interface AppConfigType {
    /** Koncový bod API */
    ApiUri: string;
    /** Jazyk */
    Language: string;
    /** Názvy pre súbory cookie */
    CookieName: {
        /** Názov cookies pre aktuálnu reláciu prihlásenia */
        LoggedSession: string;
        /** Názov cookies pre automatické prihlasovanie */
        LoggedSessionRemember: string;
        /** Informácia o odkliknutí cookies */
        CookieBar: string;
    },
    /** Nastavenia zoznamu */
    DataGrid: {
        /** Použiť modifikačnú klávesu pre úravu */
        UseShiftKey: boolean;
    },
    /** Nahrávanie súborov */
    FileUpload: {
        /** Maximálna veľkosť (MB) nahrávaného súboru (pri fotkách sa kontroluje veľkosť po zmenšení) */
        MaxFileSize: number;        
        /** Maximálny rozmer (px) obrázku pre automatické zmenšenie na strane klienta */
        ResizeMaxSize: number;
    },
    UrlPrivacy: string;
    UrlTerms: string;
}

export const AppConfig: AppConfigType = {
    ...((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 
    {
        // Vývoj 
        ApiUri: 'http://localhost:6005/api/',
        TemplatesUri: 'http://localhost:6005/templates/'
    }
    : 
    {   
        // Produkcia
        ApiUri: '/api/'
    }),
    Language: 'sk',
    // Ostatné (nezávislé od produkcie a vývoja)
    CookieName: {
        LoggedSession: '_s',
        LoggedSessionRemember: '_r',
        CookieBar: '_cb'
    },
    DataGrid: {
        UseShiftKey: false
    },
    FileUpload: {
        MaxFileSize: 200, // Rovnaký limit 200 MB, je nastaený aj v API
        ResizeMaxSize: 2000
    },
    UrlPrivacy: 'https://www.levfit.sk/ochrana-osobnych-udajov/',
    UrlTerms: 'https://www.levfit.sk/obchodne-podmienky/'
};