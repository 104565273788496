import { useState } from 'react';

// Utility

// Modely
import { Booking, BookingGroup } from '../../models/Models';

// Komponenty
import { Box, Button, Paper, Typography } from '@mui/material';
import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import BookingsListItem from './BookingsListItem';

interface BookingsListProps {
    rows?: BookingGroup[];
    onCancel?: (booking: Booking) => void;
}

const BookingsList = (props: BookingsListProps) => {

    // Zoznam
    const rowsPageSize: number = 2;
    const [rowsPageIndex, setRowsPageIndex] = useState<number>(rowsPageSize);

    return (
        <>
            {props.rows?.filter((_group, groupIndex) => groupIndex < rowsPageIndex)?.map((group, groupIndex) =>
                <Box key={groupIndex}>
                    <Paper sx={{ p: '15px 25px' }}>
                        <Typography variant='h4'>
                            {group.title}
                            <Box component="span" sx={{ color: 'silver', fontWeight: 300, fontSize: 20 }}> / {group.highlight === true ? 'Aktuálny mesiac' : 'Archív'}</Box>
                        </Typography>
                        <Typography>{group.subtitle}</Typography>
                    </Paper>
                    <Timeline
                        sx={{
                            mt: 7, pl: 0,
                            [`& .${timelineOppositeContentClasses.root}`]: { maxWidth: '105px', pl: 0 },
                        }}>
                        {/* Rezervácie - samotné záznamy */}
                        {group.list?.map((item, itemIndex) =>
                            <BookingsListItem
                                key={itemIndex}
                                item={item}
                                connector={(itemIndex + 1) < (group.list?.length ?? 0)}
                                onCancel={props.onCancel}
                            />)}
                    </Timeline>
                </Box>
            )}
            {(props.rows?.length ?? 0) > rowsPageIndex && <Button fullWidth sx={{ mt: 1, mb: 3 }} variant='contained' color='primary' onClick={() => setRowsPageIndex(prev => prev + rowsPageSize)}>Zobraziť viac</Button>}
            {props.rows !== undefined && (props.rows?.length ?? 0) === 0 &&
                <Box my={3} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                    <Typography gutterBottom variant="h6" component="div" textAlign="center">Žiadne rezervácie</Typography>
                </Box>
            }
        </>
    )
}

export default BookingsList;
