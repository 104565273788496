// Integrácia: https://www.tiny.cloud/docs/integrations/react/, https://pretagteam.com/question/loading-tinymce-without-cloud-in-react 
/*  
    Základný TinyMCE Core je navždy zdarma aj na komerčné účely.
    Ak sa editor alebo niektorá časť naťahuje z cloudu, tak musí byť doména registrovaná v cloude, inak sa zobrazí upozornenie.
    Predvolene sa všetky súčasti naťahujú automaticky z cloudu, takže pre vyhnutie sa hlášky (nepoužívania clodu) je potrebné načítať súčasti z projektu (import xy...).
    Je to tak preto, že ak by sa niekto rozhodol používať platené súčasti, stačí zapnúť súčasť a doplniť api key konfiguráciu editora a je to funkčné bez inštalácie súčastí.
*/

// Poznámky
/*
    MUI predvolene blokuje opustenie modálneho dialógového okna (Modal, Dialog, ...) to bráni v interakcii z akýmkoľvek kontextovými oknami TinyMCE. 
    Riešením je použitie atribútu "disableEnforceFocus" v nadradenom okne.
*/

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/visualblocks';

import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
//import 'tinymce/skins/content/default/content.min.css'; // poškodí layout admina

// Komponenty
import { Editor } from '@tinymce/tinymce-react';

// Vstupné parametre
export interface TinyMCEProps {
    content: string;
    contentStyle?: string;
    height?: string | number;
    onChange?: (content: string) => void;
}

// Komponent pre zoznam užívateľov
const TinyMCE = (props: TinyMCEProps) => {

    // Udalosti
    const handleEditorChange = (content: any, editor: any) => {
        if (props.onChange) {
            props.onChange(content as string);
        }
    }

    return (
        <>
            <Editor
                //initialValue={props.content}
                init={{
                    skin: false,
                    width: '100%',
                    height: props.height ?? 250,
                    promotion: false,
                    menubar: true,
                    content_css: true,
                    automatic_uploads: true,
                    paste_as_text: true,
                    paste_block_drop: false,
                    language: 'sk',
                    language_url: '/assets/tinymce-sk.js',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste code help wordcount fullscreen'
                    ],
                    toolbar:
                        'undo redo | fontsizeselect | bold italic | ' +
                        'forecolor backcolor | image link table | ' +
                        'alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | ' +
                        'removeformat | fullscreen | help',
                    toolbar_mode: 'sliding',
                    visual: true,
                    visual_table_class: 'table-visual-border',
                    image_advtab: true,
                    relative_urls: false,
                    remove_script_host: false,
                    convert_urls: false,
                    custom_elements: 'style',
                    content_style: (props.contentStyle ?? '') +
                        `          
                        body { font-family: Arial; font-size: 10.5pt; line-height: 14pt; }              
                        .table-visual-border {      border: 1px dashed rgba(136,136,136,0.3); } 
                        .table-visual-border td {   border: 1px dashed rgba(136,136,136,0.3); }
                    `
                }}
                value={props.content}
                onEditorChange={handleEditorChange}
            />
        </>
    )
}

export default TinyMCE;