import React, { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { SettingsSystem } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../utility/Convert';

// Komponenty
import { Backdrop, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { ContentSimple } from '../../layout/Content';

// Ikony
import SaveIcon from '@mui/icons-material/Save';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';
import EditIcon from '@mui/icons-material/Edit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NumbersIcon from '@mui/icons-material/Numbers';

const Settings = () => {

    // Všeobecne
    const [loading, setLoading] = React.useState(false);
    const [source, setSource] = useState<SettingsSystem>({
        bookingStartHours: 0,
        bookingStartExtendMinutes: 0,
        bookingEndHours: 0,

        creditPriceInfo: 0,
        creditPrice: 0,
        creditTitle: '',
        creditExpiration: 0,
        creditExpirationNotify: 0,

        priceFee: 0,
        priceFeeCredit: 0,

        emailStatistics: '',
        emailInvoices: '',

        company: {
            name: '',
            regId: '',
            taxId: '',
            vatId: '',
            city: '',
            street: '',
            postcode: ''
        }
    });

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeCompany = (property: string, value: any) => {
        setSource(prev => ({ ...prev, company: { ...prev.company, [property]: value } }));
    }

    // Načtať nastavenia
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'setting/system/')
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as SettingsSystem);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Uložiť nastavenia
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'setting/system', source)
            .then(response => {
                alert(response.data === true ? 'Uložené!' : 'Nastavenia sa nepodarilo uložiť!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ContentSimple>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container columnSpacing={0} sx={{ mt: 1 }} component="form" onSubmit={handleSave}>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Rezervácie</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="bookingStartHours" type="number" label="Čas do otvorenia prihlasovania (hodiny)" variant="filled" value={source.bookingStartHours ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                        inputProps={{ min: 0, max: 32767 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="bookingEndHours" type="number" label="Čas do uzávierky prihlasovania (hodiny)" variant="filled" value={source.bookingEndHours ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                        inputProps={{ min: 0, max: 32767 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="bookingStartExtendMinutes" type="number" label="Čas predĺženia prihlasovania po začatí termínu (minúty)" variant="filled" value={source.bookingStartExtendMinutes ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                        inputProps={{ min: 0, max: 32767 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Kredit</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="creditPrice" type="text" label="Cena permanentky/kreditu, výška pre dobitie (EUR)" variant="filled" value={source.creditPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EuroIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="creditPriceInfo" type="text" label="Informačná cena permanentky/kreditu pred zľavou, zobrazená iba na faktúre (EUR)" variant="filled" value={source.creditPriceInfo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EuroIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="creditExpiration" type="number" label="Platnosť permanentky/kreditu od zakúpenia v mesiacoch" variant="filled" value={source.creditExpiration ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                        inputProps={{ min: 0, max: 32767 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="creditExpirationNotify" type="number" label="Počet dní pre upozornenie na blížiaci sa koniec platnosti permanentky/kreditu (dni)" variant="filled" value={source.creditExpirationNotify ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                        inputProps={{ min: 0, max: 32767 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AccessTimeIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField name="creditTitle" label="Informačný text k permanentke/kreditu" fullWidth multiline rows={5} variant="filled" autoComplete="off" value={source.creditTitle ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 1000 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EditIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Provízie</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="priceFee" type="text" label="Provízia z jednorázovej platby (EUR)" variant="filled" value={source.priceFee ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EuroIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="priceFeeCredit" type="text" label="Provízia z kreditnej platby (EUR)" variant="filled" value={source.priceFeeCredit ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EuroIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Notifikácie</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="emailStatistics" type="text" label='Emailové adresy pre odosielanie mesačnej štatistiky (oddelené čiarkov)' variant="filled" autoComplete="off" value={source.emailStatistics ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailOutlinedIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="emailInvoices" type="text" label='Emailové adresy pre odosielanie mesačného exportu faktúr (oddelené čiarkov)' variant="filled" autoComplete="off" value={source.emailInvoices ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailOutlinedIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Firemné údaje</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="name" type="text" label='Názov' variant="filled" autoComplete="off" value={source.company?.name ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EditIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="regId" type="text" label='IČO' variant="filled" autoComplete="off" value={source.company?.regId ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <NumbersIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth name="taxId" type="text" label='DIČ' variant="filled" autoComplete="off" value={source.company?.taxId ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <NumbersIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth name="vatId" type="text" label='IČ DPH' variant="filled" autoComplete="off" value={source.company?.vatId ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <NumbersIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="street" type="text" label='Ulica a číslo' variant="filled" autoComplete="off" value={source.company?.street ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="city" type="text" label='Mesto' variant="filled" autoComplete="off" value={source.company?.city ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="postcode" type="text" label='PSČ' variant="filled" autoComplete="off" value={source.company?.postcode ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="manager" type="text" label='Štatutár / konateľ' variant="filled" autoComplete="off" value={source.company?.manager ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="iban" type="text" label='IBAN' variant="filled" autoComplete="off" value={source.company?.iban ?? ''} onChange={(e) => { handleChangeCompany(e.target.name, e.target.value) }}
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CreditCardIcon />
                                </InputAdornment>
                            ),
                        }} />
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Button type="submit" disabled={loading} variant="contained" color="info" sx={{ mr: 1 }} size="large" startIcon={<SaveIcon />}>Uložiť nastavenia</Button>
                </Grid>

            </Grid>
        </ContentSimple>
    )
}

export default Settings;
