import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, AppBar, Avatar, Box, Button, Container, Divider, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AppContext } from '../AppContext';
import { AppRouteUrl, GetRoutes } from '../AppRoutes';
import { FormatDecimal } from '../utility/Format';
import { CookieCreate, CookieRead } from '../utility/Cookies';
import { AppConfig } from '../AppConfig';

// Komponenty
import Account from '../views/system/Account';
import AccountVerify from '../views/system/AccountVerify';
import Logout from '../views/system/Logout';
import Login from '../views/system/Login';
import PasswordChange from '../views/system/PasswordChange';
import PasswordRecovery from '../views/system/PasswordRecovery';

// Ikony a assety
import logo from '../assets/logo.svg';
import { AccountCircle } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import EuroIcon from '@mui/icons-material/Euro';
import EventIcon from '@mui/icons-material/Event';
import CommentIcon from '@mui/icons-material/Comment';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

interface Props {
    children: any;
    window?: () => Window;
}

const Layout = (props: Props) => {

    // Všeobecne
    const history = useHistory();
    const context = useContext(AppContext);
    const theme = useTheme();
    const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));

    // Užívateľ
    var isLogged = (context.user?.id ?? 0) > 0;
    var isCoach = context.user?.coach ?? false;
    var isAdmin = context.user?.admin ?? false;

    // Lokálny stav
    const [loginOpen, setLoginOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
    const [passwordRecoveryOpen, setPasswordRecoveryOpen] = useState(false);
    const [anchorAccountEl, setAnchorAccountEl] = useState<null | HTMLElement>(null);

    // Nadpis a titulok stránky - automaticky po zmenej URL
    const location = useLocation();
    const findTitle = () => {
        var title = GetRoutes(context.user).find(route => route.page === location.pathname)?.title ?? '';
        // @ts-ignore
        document.title = title;
    }
    useEffect(findTitle, [findTitle]);

    // Funkcia pre vyvolanie prihlásenia
    // @ts-ignore
    window.appOpenLogin = () => setLoginOpen(true);

    // Funkcia pre vyvolanie registrácie
    // @ts-ignore
    window.appOpenAccount = () => setAccountOpen(true);

    // Cookies
    const [showCookies, setShowCookies] = useState((CookieRead(AppConfig.CookieName.CookieBar) ?? '') !== 'y');
    const hideCookiesBar = () => {
        setShowCookies(false);
        CookieCreate(AppConfig.CookieName.CookieBar, 'y', 365);
    };

    return (
        <>

            {/******* Upozornenie na cookies *******/}

            {showCookies === true &&
                <Box sx={{ position: 'fixed', width: '100%', bottom: '0px', left: '0px', zIndex: 'modal', padding: 1, maxHeight: '50%', overflowY: 'auto' }}>
                    <Alert
                        severity="warning"
                        icon={false}
                        action={<Button color="warning" size="small" variant="contained" onClick={hideCookiesBar}>Ok</Button>}>
                        Rezervačný systém rezervacie.levfit.sk používa nevyhnutné súbory cookies na zaistenie správnej funkčnosti. Používanie nevyhnutných cookies je možné aj bez vášho súhlasu. <a href="https://www.levfit.sk/subory-cookies/" style={{ color: '#333333', textDecoration: 'underline' }} target='_blank'>Viac informácii o cookies</a> a aké používame na našich ďalších stránkach <a href="https://www.levfit.sk/subory-cookies/" style={{ color: '#333333', textDecoration: 'underline' }} target='_blank'>nájdete tu.</a>
                    </Alert>
                </Box>
            }

            {/******* Možnosti (popup) *******/}

            {(loginOpen && <Login onLogged={() => window.location.reload()} onAccountCreate={() => { setLoginOpen(false); setAccountOpen(true); }} onPasswordRecovery={() => setPasswordRecoveryOpen(true)} onClose={() => setLoginOpen(false)} />)}
            {(passwordChangeOpen && <PasswordChange onClose={() => setPasswordChangeOpen(false)} />)}
            {(passwordRecoveryOpen && <PasswordRecovery onClose={() => setPasswordRecoveryOpen(!passwordRecoveryOpen)} />)}
            {(accountOpen && <Account open={accountOpen} onSave={() => { }} onClose={() => setAccountOpen(false)} />)}
            {(isLogged === false && <AccountVerify />)}

            <Box sx={{ display: 'block' }}>

                {/******* Hlavička *******/}

                <AppBar position="fixed" elevation={1} sx={{ backgroundColor: '#ffffff' }}>
                    <Toolbar sx={{
                        position: 'relative', margin: '0 auto', maxWidth: '1300px', width: '100%',
                        ...(breakpointMd === false ? { paddingBottom: '50px' } : {})
                    }}>

                        {/* Logo */}
                        <div style={{ textAlign: 'center', padding: '15px 20px 5px 0' }}>
                            <Link to='/'><img alt="" src={logo} style={{ maxWidth: '160px', width: '100%' }} /></Link>
                        </div>

                        {/******* Nadpis *******/}

                        <Box sx={{ flexGrow: 2, maxHeight: '60px', overflow: 'hidden', color: 'black', textAlign: 'center', textTransform: 'uppercase' }}>
                            {/* <Typography noWrap variant="h5" component="div">{title}</Typography> */}&nbsp;
                        </Box>

                        {/******* Možnosti *******/}

                        <Box sx={{
                            ...(breakpointMd === false ? { display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: '0px', left: '0px', width: '100%', height: '50px', background: 'rgba(0,0,0,.07)' } : {})
                        }}>
                            {isLogged === true ? (
                                <>
                                    <Button variant="text" onClick={() => history.push(AppRouteUrl.HOME)}>Harmonogram</Button>
                                    <Button variant="text" onClick={() => history.push(AppRouteUrl.BOOKING)} title="Čakajúce">Rezervácie: {context.userInfo?.booking}</Button>
                                    <Button variant="text" onClick={() => history.push(AppRouteUrl.CREDIT)} color="error" sx={{ mr: 1 }}>Kredit: {FormatDecimal(context.userInfo?.credit ?? 0, 2)} EUR</Button>
                                </>
                            ) : (
                                <>
                                    {breakpointMd === false && <Button variant="text" fullWidth onClick={() => setAccountOpen(true)} startIcon={<PersonAddIcon />}>Vytvoriť účet</Button>}
                                </>)}
                        </Box>

                        {/******* Vytvoriť účet / Registrovať / Meno prihláseného *******/}

                        {isLogged === true ? (
                            <>
                                <Button variant="contained" startIcon={<PersonIcon />} onClick={(e) => setAnchorAccountEl(e.currentTarget)}><Typography variant='body2' noWrap sx={{ maxWidth: '160px' }} component="div">{context?.user?.name ?? ''}</Typography></Button>
                            </>
                        ) : (
                            <>
                                {breakpointMd === true && <Button variant="text" onClick={() => setAccountOpen(true)} startIcon={<PersonAddIcon />} sx={{ mr: 2 }}>Vytvoriť účet</Button>}
                                <Button variant="contained" onClick={() => setLoginOpen(true)}>Prihlásiť sa</Button>
                            </>
                        )}


                    </Toolbar>
                </AppBar>

                {/******* Rozbaľovacie menu prihláseného *******/}

                {isLogged === true && (
                    <>
                        {/******* Meno *******/}
                        <Menu id="menu-account" slotProps={{ paper: { style: { minWidth: 250 } } }} anchorEl={anchorAccountEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorAccountEl)} onClose={() => setAnchorAccountEl(null)} >
                            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: 1, height: "200px" }}>
                                {context.user?.avatar?.length ? <Avatar sx={{ width: 110, height: 110, p: 0, outline: 'solid 10px whitesmoke' }} src={context.user.avatar} /> : <Avatar sx={{ opacity: .2, width: 110, height: 110, p: 0, outline: 'solid 10px whitesmoke' }}><AccountCircle sx={{ fontSize: 100 }} /></Avatar>}
                                <Typography fontWeight="bold" mt={2} variant="caption">{context?.user?.name ?? ''}</Typography>
                            </Stack>

                            {/******* Tréner (dnešné rezervácie) *******/}
                            {isCoach === true && ([
                                <MenuItem key="0" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.TODAY); }}>
                                    <ListItemIcon><EventIcon fontSize="small" /></ListItemIcon> Dnešné rezervácie
                                </MenuItem>
                                ,
                                <Divider key="1" />
                            ])}

                            {/******* Administrátor (evidencie) *******/}
                            {isAdmin === true && ([
                                <MenuItem key="2" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.EVENTS); }}>
                                    <ListItemIcon><EventIcon fontSize="small" /></ListItemIcon> Termíny
                                </MenuItem>
                                ,
                                <MenuItem key="3" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.NOTICE); }}>
                                    <ListItemIcon><CommentIcon fontSize="small" /></ListItemIcon> Oznam
                                </MenuItem>
                                ,
                                <MenuItem key="4" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.USERS); }}>
                                    <ListItemIcon><PersonIcon fontSize="small" /></ListItemIcon> Užívatelia
                                </MenuItem>
                                ,
                                <MenuItem key="5" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.STATISTICS); }}>
                                    <ListItemIcon><BarChartIcon fontSize="small" /></ListItemIcon> Štatistiky
                                </MenuItem>
                                ,
                                <MenuItem key="6" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.TEMPLATES); }}>
                                    <ListItemIcon><DesignServicesIcon fontSize="small" /></ListItemIcon> Šablóny
                                </MenuItem>
                                ,
                                <MenuItem key="6" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.SETTINGS); }}>
                                    <ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon> Nastavenia
                                </MenuItem>
                                ,
                                <Divider key="7" />
                            ])}

                            {/******* Užívateľ (moje rezervácie, kredit) *******/}
                            {isAdmin === false && isCoach === false && ([
                                <MenuItem key="8" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.BOOKING); }}>
                                    <ListItemIcon><EventIcon fontSize="small" /></ListItemIcon> Môje rezervácie: {context.userInfo?.booking}
                                </MenuItem>
                                ,
                                <MenuItem key="9" dense onClick={() => { setAnchorAccountEl(null); history.push(AppRouteUrl.CREDIT); }}>
                                    <ListItemIcon><EuroIcon fontSize="small" /></ListItemIcon> Môj kredit: {FormatDecimal(context.userInfo?.credit ?? 0, 2)} EUR
                                </MenuItem>
                                ,
                                <Divider key="10" />
                            ])}

                            {/******* Všetci (účet, odhlásiť) *******/}
                            <MenuItem dense onClick={() => { setAnchorAccountEl(null); setAccountOpen(true); }}>
                                <ListItemIcon><PersonIcon fontSize="small" /></ListItemIcon> Môj účet
                            </MenuItem>
                            <MenuItem dense onClick={() => { setAnchorAccountEl(null); setPasswordChangeOpen(true); }}>
                                <ListItemIcon><LockIcon fontSize="small" /></ListItemIcon> Zmeniť heslo
                            </MenuItem>
                            <Divider />
                            <Logout />
                        </Menu>
                    </>
                )}

                {/******* Obsah *******/}

                <Box sx={{ display: 'block', position: 'absolute', width: '100%', height: '100%' }}>
                    <Container maxWidth={false} sx={{
                        boxSizing: 'border-box', margin: '0 auto', maxWidth: '1300px', height: '100%', width: '100%', paddingTop: '110px',
                        ...(breakpointMd === false ? { paddingTop: '160px' } : {})
                    }}>
                        {props.children}
                    </Container>
                </Box>

            </Box>

        </>
    )
}
export default Layout;
