
// Modely
import { Booking } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

// Ikony
import PersonIcon from '@mui/icons-material/Person';

interface BookingsListItemProps {
    item: Booking;
    connector: boolean;
    onCancel?: (booking: Booking) => void;
}

const BookingsListItem = (props: BookingsListItemProps) => {

    return (
        <TimelineItem sx={{ ...((props.item.cancelled ?? false) === true ? { textDecoration: 'line-through', opacity: .3 } : {}) }}>

            {/* Ľava časť - čas */}
            <TimelineOppositeContent color="black" sx={{ mt: -1 }}>
                {/* Čas  */}
                <Typography variant='h4'>{dateFormat(new Date(props.item.event?.date ?? new Date()), 'HH:mm')}</Typography>

                {/* Dátum a názov dňa */}
                <Typography variant='body2' color="textSecondary">
                    {dateFormat(new Date(props.item.event?.date ?? new Date()), 'dd.MM.yyyy')}<br />
                    {props.item.event?.dateDay}
                </Typography>
            </TimelineOppositeContent>

            {/* Oddeľovač */}
            <TimelineSeparator>
                <TimelineDot />
                {props.connector && <TimelineConnector />}
            </TimelineSeparator>

            {/* Pravá časť  */}
            <TimelineContent sx={{ mt: -1, pb: 7 }}>

                {/* Názov a info  */}
                <Typography variant='h4'>
                    {/* Názov */}
                    {props.item.event?.name}&nbsp;

                    {/* Dnes */}
                    {(props.item.event?.dateToday ?? false) === true && <Chip label={<>Dnes o: <u>{dateFormat(new Date(props.item.event?.date ?? new Date()), 'HH:mm')}</u></>} color="success" size="medium" sx={{ mr: 1 }} />}

                    {/* Zrušené  */}
                    {(props.item.cancelled ?? false) === true && <Chip label="Rezervácia zrušená" color="primary" size="small" />}
                </Typography>

                {/* Tréner  */}
                <Chip
                    sx={{ my: 1 }}
                    label={props.item?.event?.coach?.name}
                    avatar={(
                        props.item.event?.coach?.fileIsImage ?
                            <Avatar sx={{ width: 48, height: 48 }} alt={props.item.event?.coach?.name} src={props.item.event?.coach?.fileSrcSmall} /> :
                            <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                    )}
                />

                {/* Popis rezervácie */}
                <Box sx={{ pl: 1 }}>
                    <Typography component='span' variant='body2'>
                        Rezervácia číslo: <u>#{props.item.id}</u> v hodnote: <u>{FormatDecimal(props.item.price ?? 0, 2)} EUR</u> bola vytvorená o: <u>{(new Date(props.item.createdDate ?? '').getFullYear() > 1 ? dateFormat(new Date(props.item.createdDate ?? ''), 'dd.MM.yyyy HH:mm') : '-')}</u>
                        {(props.item.cancelled ?? false) === true && <> a zrušená o: <u>{(new Date(props.item.cancelledDate ?? '').getFullYear() > 1 ? dateFormat(new Date(props.item.cancelledDate ?? ''), 'dd.MM.yyyy HH:mm') : '-')}</u></>}.
                    </Typography>
                </Box>

                {/* Zrušiť rezerváciu */}
                {(props.item.cancellable ?? false) === true && props.onCancel !== undefined && <Button sx={{ mt: 1 }} variant='contained' color='error' size="small" onClick={() => props.onCancel?.(props.item)}>Zrušiť rezerváciu</Button>}

            </TimelineContent>

        </TimelineItem>
    )
}

export default BookingsListItem;