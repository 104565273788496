import { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { format as dateFormat } from 'date-fns';
import { NullMinDate } from '../../utility/Date';

// Modely
import { Event } from '../../models/Models';

// Komponenty
import { Box, Chip, Drawer, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EventDetailBooking from './EventDetailBooking';

interface EventDetailProps {
    id: number;
    keepMounted?: boolean;
    onChanged?: () => void;
    onEdit?: (copy?: boolean) => void;
    onDelete?: (name: string) => void;
    onClose: () => void;
}

const EventDetail = (props: EventDetailProps) => {

    // Stav
    const [loading, setLoading] = useState<boolean>(true);
    const [source, setSource] = useState<Event>({});
    const [tabValue, setTabValue] = useState<string>('booking');

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (props.id === 0) {
            setSource({});
            setTabValue('booking');
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'event/detail/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as Event);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Formátovanie dátumu
    const formatDate = (date: any, format?: string): string => {
        if (NullMinDate(date) === null) {
            return '-';
        }
        return dateFormat(new Date(date), format ?? 'dd.MM.yyyy HH:mm');
    }

    return (
        <Drawer keepMounted={props.keepMounted ?? false} anchor="right" open={props.id > 0} onClose={props.onClose} PaperProps={{ sx: { width: '100%', maxWidth: '600px', p: 0, pt: 0 } }}>
            <Box sx={{ position: 'sticky', top: 0, pt: 2, p: 2, pb: 1, background: 'white', zIndex: 9999, borderBottom: '1px solid #F0F0F0' }}>
                <Box sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                    {props.onEdit !== undefined && (<IconButton aria-label="edit" onClick={() => props.onEdit?.(false)} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><EditIcon /></IconButton>)}
                    {props.onDelete !== undefined && (<IconButton aria-label="delete" disabled={(source.placesUsed ?? 0) > 0} onClick={() => props.onDelete?.(source.name ?? '')} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><DeleteIcon /></IconButton>)}
                    <IconButton aria-label="close" onClick={() => props.onClose()} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><CloseIcon /></IconButton>
                </Box>
                <Typography mb={1} variant="h5" sx={{ pr: '130px' }}>
                    {loading === true ? 'Čakajte...' : formatDate(source?.date, 'HH:mm') + ' - ' + source?.name}
                </Typography>
                {(source?.cancelled ?? false) === true && <Chip color="error" label="Zrušené" sx={{ mb: 1 }} />}
            </Box>
            <Box sx={{ px: 2 }}>

                <Box sx={{ mt: 1 }}>
                    <Tabs value={tabValue} onChange={(_e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab value="booking" label={'Potvrdené rezervácie (' + (source.bookings?.length ?? 0) + ')'} />
                        <Tab value="booking-cancelled" label={'Zrušené rezervácie (' + (source.bookingsCancelled?.length ?? 0) + ')'} />
                    </Tabs>
                </Box>

                <TabContext value={tabValue}>
                    <TabPanel value="booking" sx={{ px: 0 }}>
                        {(source.bookings?.length ?? 0) === 0 && <Typography variant='body2'>Zatiaľ neboli vytvorené žiadne rezervácie</Typography>}
                        {(source.bookings?.length ?? 0) > 0 && <EventDetailBooking bookings={source.bookings} />}
                    </TabPanel>
                    <TabPanel value="booking-cancelled" sx={{ px: 0 }}>
                        {(source.bookingsCancelled?.length ?? 0) === 0 && <Typography variant='body2'>Zatiaľ neboli zrušené žiadne rezervácie</Typography>}
                        {(source.bookingsCancelled?.length ?? 0) > 0 && <EventDetailBooking bookings={source.bookingsCancelled} />}
                    </TabPanel>
                </TabContext>

            </Box>
        </Drawer>
    )
}

export default EventDetail