
// Modely
import { EventLite } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';

// Komponenty
import { Avatar, Badge, Box, Chip, Typography } from '@mui/material';
import { TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import EventDetailBooking from '../event/EventDetailBooking';

interface TodayItemProps {
    item: EventLite;
    connector: boolean;
}

const TodayItem = (props: TodayItemProps) => {

    return (
        <TimelineItem sx={{ ...((props.item.cancelled ?? false) === true ? { textDecoration: 'line-through', opacity: .3 } : {}) }}>

            {/* Ľava časť - čas */}
            <TimelineOppositeContent color="black" sx={{ mt: -1 }}>
                {/* Čas  */}
                <Typography variant='h4'>{dateFormat(new Date(props.item?.date ?? new Date()), 'HH:mm')}</Typography>

                {/* Dátum a názov dňa */}
                <Typography variant='body2' color="textSecondary">
                    {dateFormat(new Date(props.item?.date ?? new Date()), 'dd.MM.yyyy')}<br />
                    {props.item?.dateDay}
                </Typography>
            </TimelineOppositeContent>

            {/* Oddeľovač */}
            <TimelineSeparator>
                <TimelineDot />
                {props.connector && <TimelineConnector />}
            </TimelineSeparator>

            {/* Pravá časť  */}
            <TimelineContent sx={{ mt: -1, pb: 7 }}>

                {/* Názov a info  */}
                <Typography variant='h4'>
                    {/* Názov */}
                    {props.item?.name}&nbsp;
                </Typography>

                {/* Tréner  */}
                <Box sx={{ my: 1 }}>
                    <Badge badgeContent={(props.item?.bookings?.length ?? 0) > 0 ? props.item?.bookings?.length : 'Bez rezervácie'} color={(props.item?.bookings?.length ?? 0) > 0 ? 'success' : 'error'}>
                        <Chip
                            label={props.item?.coach?.name}
                            avatar={(
                                props.item?.coach?.fileIsImage ?
                                    <Avatar sx={{ width: 48, height: 48 }} alt={props.item?.coach?.name} src={props.item?.coach?.fileSrcSmall} /> :
                                    <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                            )}
                        />
                    </Badge>
                </Box>

                {/* Rezervácie */}
                {(props.item?.bookings?.length ?? 0) > 0 && <EventDetailBooking bookings={props.item.bookings} />}

            </TimelineContent>
        </TimelineItem>
    )
}

export default TodayItem;