import React, { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { SettingsTemplates } from '../../models/Models';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { ContentSimple } from '../../layout/Content';
import TinyMCE from '../../components/TinyMCE';

// Ikony
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Templates = () => {

    // Všeobecne
    const [loading, setLoading] = React.useState(false);
    const [source, setSource] = useState<SettingsTemplates>({
        emailTemplateCreditPaid: '',
        emailTemplateCreditExpirationNotify: '',
        emailTemplateCreditExpired: '',
        emailTemplateBookingOnce: '',
        emailTemplateBookingCredit: '',

        invoiceTemplateCreditPaid: '',
        invoiceTemplateCreditExpired: '',
        invoiceTemplateBookingOnce: '',
        invoiceTemplateBookingCredit: ''
    });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Načtať nastavenia
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'setting/templates/')
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as SettingsTemplates);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Uložiť nastavenia
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'setting/templates', source)
            .then(response => {
                alert(response.data === true ? 'Uložené!' : 'Nastavenia sa nepodarilo uložiť!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ContentSimple>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container columnSpacing={0} sx={{ mt: 1 }} component="form" onSubmit={handleSave}>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Šablóny pre emailové správy</Typography>
                </Grid>

                <Grid item xs={12}>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateRegistration')} onChange={() => handleToggleAccordion('emailTemplateRegistration')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Nová registrácia</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [url]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateRegistration', s)} content={source.emailTemplateRegistration ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplatePasswordRecovery')} onChange={() => handleToggleAccordion('emailTemplatePasswordRecovery')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Zabudnuté heslo - overenie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [url]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplatePasswordRecovery', s)} content={source.emailTemplatePasswordRecovery ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplatePasswordRecoveryChanged')} onChange={() => handleToggleAccordion('emailTemplatePasswordRecoveryChanged')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Zabudnuté heslo - nové heslo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [password]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplatePasswordRecoveryChanged', s)} content={source.emailTemplatePasswordRecoveryChanged ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={12} mt={2}>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateCreditPaid')} onChange={() => handleToggleAccordion('emailTemplateCreditPaid')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Potvrdenie o dobití kreditu a platbe za skupinové cvičenie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [name], [price]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateCreditPaid', s)} content={source.emailTemplateCreditPaid ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateCreditExpirationNotify')} onChange={() => handleToggleAccordion('emailTemplateCreditExpirationNotify')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Upozornenie na blížiacu sa exspiráciu kreditu</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [name]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateCreditExpirationNotify', s)} content={source.emailTemplateCreditExpirationNotify ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateCreditExpired')} onChange={() => handleToggleAccordion('emailTemplateCreditExpired')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Oznámenie o exspirácii kreditu</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [name]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateCreditExpired', s)} content={source.emailTemplateCreditExpired ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateBookingOnce')} onChange={() => handleToggleAccordion('emailTemplateBookingOnce')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Potvrdenie o účasti na skupinovom cvičení (jednorázovo)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [name]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateBookingOnce', s)} content={source.emailTemplateBookingOnce ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('emailTemplateBookingCredit')} onChange={() => handleToggleAccordion('emailTemplateBookingCredit')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Potvrdenie o účasti na skupinovom cvičení (z kreditu)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' mb={1} color='silver'>Značky: [name]</Typography>
                            <TinyMCE height="300px" onChange={(s) => handleChange('emailTemplateBookingCredit', s)} content={source.emailTemplateBookingCredit ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={12} my={3}>
                    <Typography variant='h4'>Šablóny pre pdf doklady</Typography>
                </Grid>

                <Grid item xs={12}>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('invoiceTemplateCreditPaid')} onChange={() => handleToggleAccordion('invoiceTemplateCreditPaid')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Doklad o zakúpenom kredite</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' color='silver'>Značky: [number], [date], [iban], [vs], [price], [price-discount], [price-total], [price-total-to-pay], [manager]</Typography>
                            <Typography variant='body2' color='silver'>Dodávateľ: [supplier], [supplier-street], [supplier-postcode], [supplier-city], [supplier-reg-id], [supplier-tax-id], [supplier-vat-id]</Typography>
                            <Typography variant='body2' color='silver' mb={1}>Odberateľ: [customer], [customer-street], [customer-postcode], [customer-city], [customer-email], [customer-phone]</Typography>
                            <TinyMCE height="600px" onChange={(s) => handleChange('invoiceTemplateCreditPaid', s)} content={source.invoiceTemplateCreditPaid ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('invoiceTemplateCreditExpired')} onChange={() => handleToggleAccordion('invoiceTemplateCreditExpired')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Doklad o exspirácií kreditu</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' color='silver'>Značky: [number], [date], [iban], [vs], [price], [price-discount], [price-total], [price-total-to-pay], [manager]</Typography>
                            <Typography variant='body2' color='silver'>Dodávateľ: [supplier], [supplier-street], [supplier-postcode], [supplier-city], [supplier-reg-id], [supplier-tax-id], [supplier-vat-id]</Typography>
                            <Typography variant='body2' color='silver' mb={1}>Odberateľ: [customer], [customer-street], [customer-postcode], [customer-city], [customer-email], [customer-phone]</Typography>
                            <TinyMCE height="600px" onChange={(s) => handleChange('invoiceTemplateCreditExpired', s)} content={source.invoiceTemplateCreditExpired ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('invoiceTemplateBookingOnce')} onChange={() => handleToggleAccordion('invoiceTemplateBookingOnce')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Doklad o realizovanej aktivite občianskeho združenia (jednorázovo)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' color='silver'>Značky: [number], [date], [iban], [vs], [price], [price-discount], [price-total], [price-total-to-pay], [manager]</Typography>
                            <Typography variant='body2' color='silver'>Dodávateľ: [supplier], [supplier-street], [supplier-postcode], [supplier-city], [supplier-reg-id], [supplier-tax-id], [supplier-vat-id]</Typography>
                            <Typography variant='body2' color='silver' mb={1}>Odberateľ: [customer], [customer-street], [customer-postcode], [customer-city], [customer-email], [customer-phone]</Typography>
                            <TinyMCE height="600px" onChange={(s) => handleChange('invoiceTemplateBookingOnce', s)} content={source.invoiceTemplateBookingOnce ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('invoiceTemplateBookingCredit')} onChange={() => handleToggleAccordion('invoiceTemplateBookingCredit')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Doklad o realizovanej aktivite občianskeho združenia (z kreditu)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body2' color='silver'>Značky: [number], [date], [iban], [vs], [price], [price-discount], [price-total], [price-total-to-pay], [manager]</Typography>
                            <Typography variant='body2' color='silver'>Dodávateľ: [supplier], [supplier-street], [supplier-postcode], [supplier-city], [supplier-reg-id], [supplier-tax-id], [supplier-vat-id]</Typography>
                            <Typography variant='body2' color='silver' mb={1}>Odberateľ: [customer], [customer-street], [customer-postcode], [customer-city], [customer-email], [customer-phone]</Typography>
                            <TinyMCE height="600px" onChange={(s) => handleChange('invoiceTemplateBookingCredit', s)} content={source.invoiceTemplateBookingCredit ?? ''} contentStyle='body { padding: 0px 10px; }' />
                        </AccordionDetails>
                    </Accordion>

                </Grid>

                <Grid item xs={12} mt={2}>
                    <Button type="submit" disabled={loading} variant="contained" color="info" sx={{ mr: 1 }} size="large" startIcon={<SaveIcon />}>Uložiť nastavenia</Button>
                </Grid>

            </Grid>
        </ContentSimple>
    )
}

export default Templates;
