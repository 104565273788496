import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility

// Modely
import { EventLite } from '../../models/Models';

// Komponenty
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { ContentSimple, ContentTitle } from '../../layout/Content';
import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import TodayItem from './TodayItem';

const Today = () => {

    // Všeobecne
    const [loading, setLoading] = React.useState(false);

    // Tabuľka
    const [rows, setRows] = useState<EventLite[] | undefined>(undefined);

    // Rozdelené dnes a zajtra
    const [rowsToday, setRowsToday] = useState<EventLite[] | undefined>(undefined); // 'undefined', aby sa pri vstupe nezobrazilo (žiadne záznamy)
    const [rowsTomorrow, setRowsTomorrow] = useState<EventLite[] | undefined>(undefined); // 'undefined', aby sa pri vstupe nezobrazilo (žiadne záznamy)

    // Automatické rozdelenie záznamov dnes a zajtra
    useEffect(() => {
        setRowsToday(rows?.filter(item => item.dateToday === true));
        setRowsTomorrow(rows?.filter(item => item.dateToday !== true));
    }, [rows]);

    // Načtať kompletné dáta
    const loadData = useCallback(() => {
        axios
            .get(AppConfig.ApiUri + 'event/today/')
            .then(response => {
                if (response.data !== null) {
                    setRows(response.data as EventLite[]);
                }
                else {
                    setRows([]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        setLoading(true);
        loadData();
        // Vykonám kontrolu každých 30 sekúnd (už nezobrazujem preloader, ani nepreskočím na prvú stranu)
        const interval = setInterval(() => loadData(), (30 * 1000));
        return () => clearInterval(interval);
    }, []);

    return (
        <ContentSimple>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Nadpis */}
            <ContentTitle title="Dnešné" subtitle="Rezervácie" />

            {/* Rezervácie - dnes */}
            <Timeline
                sx={{
                    mt: 4, pl: 0,
                    [`& .${timelineOppositeContentClasses.root}`]: { maxWidth: '105px', pl: 0 },
                }}>
                {rowsToday?.map((item, itemIndex) =>
                    <TodayItem
                        key={itemIndex}
                        item={item}
                        connector={(itemIndex + 1) < (rowsToday?.length ?? 0)}
                    />)}
            </Timeline>

            {rowsToday !== undefined && (rowsToday?.length ?? 0) === 0 &&
                <Box my={3} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                    <Typography gutterBottom variant="h6" component="div" textAlign="center">Žiadne rezervácie</Typography>
                </Box>
            }

            {/* Nadpis */}
            <ContentTitle title="Zajtrajšie" subtitle="Rezervácie" />

            {/* Rezervácie - zajtra */}
            <Timeline
                sx={{
                    mt: 4, pl: 0,
                    [`& .${timelineOppositeContentClasses.root}`]: { maxWidth: '105px', pl: 0 },
                }}>
                {rowsTomorrow?.map((item, itemIndex) =>
                    <TodayItem
                        key={itemIndex}
                        item={item}
                        connector={(itemIndex + 1) < (rowsTomorrow?.length ?? 0)}
                    />)}
            </Timeline>

            {rowsTomorrow !== undefined && (rowsTomorrow?.length ?? 0) === 0 &&
                <Box my={3} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                    <Typography gutterBottom variant="h6" component="div" textAlign="center">Žiadne rezervácie</Typography>
                </Box>
            }

        </ContentSimple>
    )
}

export default Today;
