import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import axios from 'axios';
import { useState, FormEvent } from 'react'
import { AppConfig } from '../../AppConfig';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    onClose: () => void;
}

const PasswordRecovery = (props: Props) => {

    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [alert, setAlert] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('warning');

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString() ?? '';
        const emailIsValid = /\S+@\S+\.\S+/.test(email);

        setEmailError(!emailIsValid);

        if (!emailIsValid) {
            setAlertSeverity('error');
            setAlert('Musíte vyplniť: email!');
            return;
        }
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'user/passwordrecovery', null, { params: { 'email': email } })
            .then(response => {
                if (response.data === 'success') {
                    setAlertSeverity('success');
                    setAlert('Email bol úspešne odoslaný!');
                    (event.target as HTMLFormElement).reset();
                } else {
                    setAlertSeverity('error');
                    setAlert('Email sa nepodarilo odoslať!');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog maxWidth="sm" fullWidth open={true} onClose={props.onClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Zabudnuté heslo</DialogTitle>
                    <IconButton aria-label="close" onClick={props.onClose} sx={{ position: 'absolute', right: 8, top: 8, opacity: .8, background: 'whitesmoke' }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <DialogContentText>
                            Na Vašu emailovú adresu bude odoslaný overovací email, pomocou ktorého bude možné vygenerovať nové heslo.
                        </DialogContentText>
                        <TextField sx={{ mt: 3 }} name="email" type="email" error={emailError} required label="Email" fullWidth variant="standard" autoComplete="email" />
                        {(alert.length > 0 && <Alert sx={{ mt: 1 }} severity={alertSeverity as AlertColor}>{alert}</Alert>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Odoslať</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PasswordRecovery;