import { Switch, Route, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import axios from 'axios';

// Nastavenia
import { AppConfig } from './AppConfig';
import { GetRoutes } from './AppRoutes';

// Utility
import { CookieCreate, CookieRead } from './utility/Cookies';

// Téma
import ThemeDefault from './theme/ThemeDefault';
import GlobalStyles from './theme/GlobalStyles';

// Podstránky
import Layout from './layout/Layout';

import "@fontsource/roboto-condensed"; // Defaults to weight 400.
import "@fontsource/roboto-condensed/300.css"; // Specify weight
import { useState, useCallback, useEffect } from 'react';
import { AppContext, AppContextModel, AppContextUserInfo } from './AppContext';
import Debounce from './utility/Debounce';

enum LoggedType {
    Verification,
    Unauthorized,
    Authorized,
}

const App = () => {

    const history = useHistory();
    const location = useLocation();

    // Funkcia pre zmenu url z externého javascriptu
    // Táto funkcia musí byť vložená do komponentu, ktorý je vložený až pod komponentom "Switch", preto nemôže byť v komponente "Layout"
    // Komponent "Content" je použitý na každej podstránke, takže je použiteľný všade, ak by nebol, stačilo byť vloži pod "Switch" čistý komponent s touto funkciou
    // @ts-ignore
    window.externalHistoryPush = (url) => {
        history.push(url);
    };

    // Kontext musí byť uložený v stave rodiča, aby sa zbytočne neprekreslovali komponenty: https://legacy.reactjs.org/docs/context.html#caveats 
    const [appContext, setAppContext] = useState<AppContextModel>({
        // Predvolené hodnoty
        user: undefined,
        userInfo: undefined,
        // Funkcia pre obnovenie informácii o užívateľovi
        userInfoLoad: () => handleUserInfoLoad()
    });

    // Získa rozšírené informácie o prihlásenom užívateľovi
    const handleUserInfoLoad = Debounce(() => {
        axios
            .get(AppConfig.ApiUri + 'user/info/')
            .then(response => {
                if (response.data !== null) {
                    setAppContext?.(prev => ({
                        ...prev,
                        userInfo: response.data as AppContextUserInfo
                    }));
                }
            });
    }, 250);

    // Autorizácia všetkých volaní pomocou globálnej hlavičky
    axios.defaults.headers.common['session'] = (() => {
        return CookieRead(AppConfig.CookieName.LoggedSession) ?? '';
    })();

    // Kontrola prihláseného užívateľa (cookie je vytvorené kvôli automatickému prihláseniu a predĺženiu platnosti)
    const [logged, setLogged] = useState<LoggedType>(LoggedType.Verification);
    const handleLogged = useCallback(() => {
        axios
            .post(AppConfig.ApiUri + 'user/logged', null, {
                headers: {
                    'session': CookieRead(AppConfig.CookieName.LoggedSession) ?? '',
                    'sessionRemember': CookieRead(AppConfig.CookieName.LoggedSessionRemember) ?? ''
                }
            })
            .then(response => {
                // Ak prihlásenie nie je platné odstránim cookies a nastavím stav "unauthorized"
                if (response.data.message === 'unauthorized') {
                    setLogged(LoggedType.Unauthorized);
                }
                // Ak je prihlásenie platné, tak vytvorím cookies a nastavím stav "authorized"
                if (response.data.message === 'authorized') {
                    if (response.data.session !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSession, response.data.session, 1);
                        setLogged(LoggedType.Authorized);
                    }
                    if (response.data.sessionRemember !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSessionRemember, response.data.sessionRemember, 365);
                    }
                    // Nastavím základné údaje zo security session
                    setAppContext(prev => ({
                        ...prev,
                        user: {
                            id: response.data.id ?? 0,
                            admin: response.data.admin ?? false,
                            coach: response.data.coach ?? false,
                            name: response.data.name ?? '',
                            avatar: response.data.avatar ?? '',
                        }
                    }));
                    // Obnovím rozšírené informácie o užívateľovi
                    appContext.userInfoLoad?.();
                }
            })
            .catch(err => {
                // Iba dočasne odhlásim, keďže mohlo jednorázovo vypadnúť API (nechcene by to mohlo odhlasovať, cookies odstráni API)
                setLogged(LoggedType.Unauthorized);
                setAppContext(prev => ({ ...prev, user: undefined }));
            });
    }, []);

    // Automatická kontrola url (až po overení session, inak by sa vykonal presmerovani vždy)
    useEffect(() => {
        if (logged === LoggedType.Verification) {
            return;
        }
        var route = GetRoutes(appContext.user).find(route => route.page === location.pathname);
        if (route === undefined) {
            window.location.href = '/';
        }
    }, [location.pathname, logged]);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        if (logged === LoggedType.Verification) {
            handleLogged();
        }
        // Vykonám kontrolu každú minútu
        if (logged === LoggedType.Authorized) {
            const interval = setInterval(() => {
                handleLogged();
            }, (60 * 1000));
            return () => clearInterval(interval);
        }
    }, [logged, handleLogged]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={ThemeDefault}>
                <GlobalStyles />
                <CssBaseline />
                <AppContext.Provider value={{ ...appContext, setContext: setAppContext }}>
                    <Layout>
                        <Switch>
                            {GetRoutes(appContext.user).map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.page.toString()}
                                        exact={route.exact}
                                        render={(props: RouteComponentProps<any>) => (
                                            <route.component
                                                title={route.title}
                                                {...props}
                                                {...route.props}
                                            />
                                        )} />
                                )
                            }
                            )}
                        </Switch>
                    </Layout>
                </AppContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App;