import React, { useCallback } from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { format as dateFormat } from 'date-fns';
import parseHtml from 'html-react-parser';

// Modely
import { EventLite, EventLiteGroup, Setting } from '../../models/Models';
import { AppContext } from '../../AppContext';

// Komponenty
import { Backdrop, Box, Button, CircularProgress, Grid, Link, Paper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ContentSimple, ContentTitle } from '../../layout/Content';
import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ScheduleItem from './ScheduleItem';

// Ikony
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const Schedule = () => {

    // Všeobecne
    const theme = useTheme();
    const context = useContext(AppContext);
    const [loading, setLoading] = React.useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [expanded, setExpanded] = useState<number[]>([]);
    const [notice, setNotice] = useState<string>('');
    const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));

    // Načtať oznam
    const loadNotice = () => {
        axios
            .get(AppConfig.ApiUri + 'setting/notice/')
            .then(response => {
                if (response.data !== null) {
                    setNotice((response.data as Setting).value ?? '');
                }
            });
    };
    useEffect(() => loadNotice(), []);

    // Tabuľka
    const [rows, setRows] = useState<EventLiteGroup[] | undefined>(undefined); // 'undefined', aby sa pri vstupe nezobrazilo (žiadne záznamy)

    // Načtať kompletné dáta
    const loadData = useCallback(() => {
        axios
            .get(AppConfig.ApiUri + 'event/schedule')
            .then(response => {
                if (response.data !== null) {
                    setRows(response.data as EventLiteGroup[]);
                }
                else {
                    setRows([]);
                }
            })
            .finally(() => {
                setLoading(false);
            });;
    }, []);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        setLoading(true);
        loadData();
        // Vykonám kontrolu každých 10 sekúnd (už nezobrazujem preload, ani nepreskočím na prvú stranu)
        const interval = setInterval(() => loadData(), (10 * 1000));
        return () => clearInterval(interval);
    }, []);

    // Vytvorenie rezervácie
    const handleCreate = (event: EventLite) => {
        setConfirm(prev => ({
            ...prev, open: true,
            title: 'Vytvorenie rezervácie',
            children: <>
                <u>{dateFormat(new Date(event.date ?? new Date()), 'HH:mm') + ' - ' + event.name + ' (' + dateFormat(new Date(event.date ?? new Date()), 'dd.MM.yyyy') + ')'}</u><br /><br />
                Kliknutím na tlačidlo "áno" potvrdzujete, že ste sa oboznámili s <Link sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }} href={AppConfig.UrlTerms} target="_blank">obchodnými podmienkami</Link> a súhlasíte s povinnosťou platby.
            </>,
            onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'event/createbooking/' + event.id)
                    .then(response => {
                        if (response.data) {
                            // Rezervácia bola vytvorená, bez presmerovania na platbu (obnovím context kvôli prepočtu kreditu)
                            if (response.data.success === true && response.data.state === 0) {
                                alert('Rezervácia bola úspešne vytvorená!\nĎakujeme');
                                context.userInfoLoad?.();
                                return;
                            }

                            // Kredit nie je dostatočný, ale rezervácia môže byť vytvorená s presmerovaním na platobnú bránu
                            if (response.data.success === true && response.data.state === 1) {
                                window.location.href = response.data.data;
                                return;
                            }

                            // Len pre prihlásených
                            if (response.data.data === 'unauthorized') {
                                // @ts-ignore
                                window.appOpenLogin?.();
                                alert('Ľutujeme, ale rezerváciu môže vytvoriť len prihlásený užívateľ!\nNajskôr sa prosím prihláste do svojho účtu.');
                                return;
                            }

                            // Len pre užívateľov (nie admin, ani tréner)
                            if (response.data.data === 'badrole') {
                                alert('Rezervácia termínu je povolená len pre bežných užívateľov!');
                                return;
                            }

                            // Ukončené prihlasovanie
                            if (response.data.data === 'closed') {
                                alert('Ľutujeme, ale prihlasovanie už bolo ukončené!');
                                return;
                            }

                            // Termín bol zrušený
                            if (response.data.data === 'cancelled') {
                                alert('Ľutujeme, ale tento termín bol zrušený!');
                                return;
                            }

                            // Rezervácia už existuje
                            if (response.data.data === 'exists') {
                                alert('Rezervácia na tento termín už existuje!');
                                return;
                            }

                            // Neznáma chyba
                            alert('Ľutujeme, ale rezerváciu už nebolo možné vytvoriť.\nNastala neznáma chyba, prosím kontaktujte nás!');
                        }
                    })
                    .catch(() => alert('Ľutujeme, ale rezerváciu už nebolo možné vytvoriť.\nNastala neznáma chyba, prosím kontaktujte nás!'))
                    .finally(() => {
                        // Vždy obnovím zoznam
                        loadData();
                        setLoading(false);
                    });
            }
        }));
    };

    // Zrušenie rezervácie
    const handleCancel = (event: EventLite) => {
        // Skontrolujem rezerváciu na danú udalosť, ak neexistuje tak nepokračujem a obnovím zoznam
        if ((event.bookingCreated ?? false) === false || (event.bookingId ?? 0) === 0) {
            loadData();
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Zrušenie rezervácie',
            children: <>
                <u>{event.name} - {dateFormat(new Date(event.date ?? new Date()), 'HH:mm') + ' (' + dateFormat(new Date(event.date ?? new Date()), 'dd.MM.yyyy') + ')'}</u><br />
                Skutočne chcete zrušiť Vašu rezerváciu?
            </>,
            onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'booking/cancel/' + event.bookingId)
                    .then(response => {
                        if (response.data) {

                            // Rezervácia zrušená (obnovím zoznam a context kvôli prepočtu kreditu)
                            if (response.data.success === true) {
                                loadData();
                                context.userInfoLoad?.();
                                return;
                            }

                            // Rezerváciu už nebolo možné zrušiť
                            if (response.data.success === false && response.data.data === 'closed') {
                                loadData();
                                alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nPrihlasovanie je už uzavreté!');
                                return;
                            }

                            // Neznáma chyba
                            alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nNastala neznáma chyba, prosím kontaktujte nás!');
                        }
                    })
                    .catch(() => alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nNastala neznáma chyba, prosím kontaktujte nás!'))
                    .finally(() => setLoading(false));
            }
        }));
    };

    // Prepnúť rozbaľovanie
    const handleToggleExpanded = (index: number) => setExpanded(prev => prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]);

    return (
        <ContentSimple>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Nadpis */}
            <ContentTitle title="Harmonogram" subtitle="Cvičení" />

            {/* Oznam (hore) */}
            {!breakpointMd === true && notice.length > 0 && (
                <Grid item xs={12} md={4}>
                    <Box mb={4}>
                        <div className='html-description'>{parseHtml(notice)}</div>
                    </Box>
                </Grid>
            )}

            <Grid container columnSpacing={5}>

                {/* Terminy (ľavý panel) */}
                <Grid item xs={12} md>
                    {/* Termíny - zoskupenie po dňoch */}
                    {rows?.map((group, groupIndex) =>
                        <Box key={groupIndex} mt={1}>
                            <Paper sx={{ position: 'relative', p: '15px 25px' }}>
                                {/* Expand / Collapse */}
                                {(group.highlight ?? false) === false && <>
                                    <Button onClick={() => handleToggleExpanded(groupIndex)} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }}></Button>
                                    <ExpandCircleDownIcon sx={{ position: 'absolute', top: '50%', marginTop: '-17.5px', opacity: .2, right: '30px', ...(expanded.includes(groupIndex) ? { transform: 'rotate(180deg)' } : {}) }} fontSize="large" />
                                </>}
                                <Typography variant='h4'>
                                    {group.title}
                                    <Box component="span" sx={{ color: 'red', fontSize: 20 }}>{group.highlight === true ? ' / Dnes' : ''}</Box>
                                </Typography>
                                <Typography>{group.subtitle}</Typography>
                            </Paper>
                            {(expanded.includes(groupIndex) || (group.highlight ?? false) === true) && <>
                                <Timeline
                                    sx={{
                                        mt: 7, pl: 0,
                                        [`& .${timelineOppositeContentClasses.root}`]: { maxWidth: '105px', pl: 0 },
                                    }}>
                                    {/* Termíny - samotné záznamy */}
                                    {group.list?.map((item, itemIndex) =>
                                        <ScheduleItem
                                            key={itemIndex}
                                            item={item}
                                            connector={(itemIndex + 1) < (group.list?.length ?? 0)}
                                            onCreate={handleCreate}
                                            onCancel={handleCancel}
                                        />)}
                                </Timeline>
                            </>}
                        </Box>
                    )}
                    {/* Žiadne záznamy */}
                    {rows !== undefined && (rows?.length ?? 0) === 0 &&
                        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                            <Typography gutterBottom variant="h6" component="div" textAlign="center">Momentálne nie sú k dispozícii žiadne termíny</Typography>
                        </Box>
                    }
                </Grid>

                {/* Oznam (pravý panel) */}
                {breakpointMd === true && notice.length > 0 && (
                    <Grid item xs={12} md={4}>
                        <Box mb={4}>
                            <div className='html-description'>{parseHtml(notice)}</div>
                        </Box>
                    </Grid>
                )}

            </Grid>
        </ContentSimple>
    )
}

export default Schedule;
