import React from 'react';
import { useState } from 'react';

// Modely
import { Credit } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from '@mui/material';

// Ikony

// Tabuľka - štýl
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: '2px solid #333333'
    },
    [`&.${tableCellClasses.body}`]: {

    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface UserDetailCreditsProps {
    rows?: Credit[];
    rowsPageSize?: number;
}

const UserDetailCredits = (props: UserDetailCreditsProps) => {

    // Tabuľka
    const rowsPageSize: number = props.rowsPageSize ?? 10;
    const [rowsPageIndex, setRowsPageIndex] = useState<number>(rowsPageSize);

    return (
        <>
            {/* Pohyby */}
            {(props.rows?.length ?? 0) > 0 && (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ width: 150 }}>
                                        Vytvorené<br />
                                        Stav
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Rezervácia<br />
                                        Poznámka
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ width: 100, textAlign: 'right' }}>
                                        Faktúra<br />
                                        Suma
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows?.map((row, index) => index < rowsPageIndex ? (
                                    <StyledTableRow key={row.id} sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        ...((row.storno ?? false) === true ? { textDecoration: 'line-through', opacity: .3 } : {})
                                    }}>
                                        <StyledTableCell component="th" scope="row">
                                            {(new Date(row.createdDate ?? '').getFullYear() > 1 ? dateFormat(new Date(row.createdDate ?? ''), 'dd.MM.yyyy HH:mm') : '-')}<br />
                                            {(row.storno ?? false) === true ? 'Stornované' : 'Zaúčtované'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {(row.bookingId ?? 0) > 0 ? '#' + row.bookingId + ', ' + row.bookingName + ', ' + dateFormat(new Date(row.bookingDate ?? '2000'), 'dd.MM.yyyy HH:mm') : '-'}<br />
                                            {(row.price ?? 0) > 0 ? 'Platnosť do: ' + dateFormat(new Date(row.expirationDate ?? ''), 'dd.MM.yyyy HH:mm') + '. ' : ''} {row.note} {(row.paymentId ?? 0) > 0 ? 'Identifikátor platby: #' + row.paymentId : ''}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" sx={{ textAlign: 'right' }}>
                                            {(row.invoiceNumber ?? '').length > 0 ? row.invoiceNumber : '-'}<br />
                                            <span style={{ color: (row.price ?? 0) < 0 ? 'red' : 'green' }}>{((row.price ?? 0) > 0 ? '+' : '') + FormatDecimal(row.price ?? 0, 2)} EUR</span>

                                        </StyledTableCell>
                                    </StyledTableRow >
                                ) :
                                    (
                                        <React.Fragment key={row.id}></React.Fragment>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {(props.rows?.length ?? 0) > rowsPageIndex && <Button fullWidth sx={{ mt: 1, mb: 3 }} variant='contained' color='primary' onClick={() => setRowsPageIndex(prev => prev + rowsPageSize)}>Zobraziť viac</Button>}
                    {(props.rows?.length ?? 0) === 0 &&
                        <Box my={3} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: '100%', opacity: .3 }}>
                            <Typography gutterBottom variant="h6" component="div" textAlign="center">Žiadne záznamy</Typography>
                        </Box>
                    }
                </>)}
        </>
    )
}

export default UserDetailCredits;
