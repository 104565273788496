import axios from 'axios';
import React, { useState } from 'react'
import { AppConfig } from '../../AppConfig';
import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, Grid, IconButton } from '@mui/material';

// Ikony
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    onClose: () => void;
}

const PasswordChange = (props: Props) => {

    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = useState('');
    const [warningSeverity, setWarningSeverity] = useState('warning');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const password = data.get('password')?.toString() ?? '';
        const passwordVerify = data.get('passwordverify')?.toString() ?? '';

        if (password.length === 0) {
            setWarningSeverity('warning');
            setWarning('Musíte vyplniť: heslo!');
            return;
        }
        if (password !== passwordVerify) {
            setWarningSeverity('warning');
            setWarning('Heslá sa nezhodujú!');
            return;
        }

        setWarning('');
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user/passwordchange', null, {
                params: {
                    'password': password
                }
            })
            .then(response => {
                if (response.data === 'success') {
                    setWarningSeverity('success');
                    setWarning('Uložené!');
                    (event.target as HTMLFormElement).reset();
                } else {
                    setWarningSeverity('error');
                    setWarning('Záznam sa nepodarilo uložiť!');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog keepMounted={false} maxWidth="xs" fullWidth open={true} scroll="body" onClose={(_e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Zmena hesla</DialogTitle>
                    <IconButton aria-label="close" onClick={props.onClose} sx={{ position: 'absolute', right: 8, top: 8, opacity: .8, background: 'whitesmoke' }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="password" type="password" required label="Heslo" fullWidth variant="outlined" autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="passwordverify" type="password" required label="Heslo znovu" fullWidth variant="outlined" autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {(warning.length > 0 && <Grid item xs={12}><Alert sx={{ mt: 2 }} severity={warningSeverity as AlertColor}>{warning}</Alert></Grid>)}

                            <Grid item xs={12} mt={2}>
                                <Button type="submit" disabled={loading} sx={{ mt: 1, mb: 1 }} fullWidth size="large" variant="contained">Uložiť</Button>
                                <Button type="button" disabled={loading} sx={{ mb: 1 }} fullWidth size="large" variant="outlined" onClick={props.onClose}>Zrušiť</Button>
                            </Grid>

                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>
        </>
    )
}

export default PasswordChange;
