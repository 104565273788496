import { createContext } from 'react';

// Poznámky
// --------------------------------------
// Príklad zmeny kontextu cez vnorený komponent: appContext.setContext?.(prev => ({ ...prev, user: { ...appContext.user, name: 'New Name' } }));
// 
// 
// 
// 
// 

// Predpis pre globálny obsah aplikácie
export interface AppContextModel {

    // Callback pre zmenu kontextu z podradených elementov
    setContext?: (action: React.SetStateAction<AppContextModel>) => void;

    // Hodnoty v kontexte
    user?: AppContextUser;
    userInfo?: AppContextUserInfo;
    userInfoLoad?: () => void;

};

// Základné informácie o užívateľovi zo security session
export interface AppContextUser {
    id?: number;
    admin?: boolean;
    coach?: boolean;
    name?: string;
    avatar?: string;
}

// Rozšírené informácie o užívateľovi (načítané samostatne)
export interface AppContextUserInfo {
    booking?: number;
    credit?: number;
}

// Zabezpečuje prenos globálnych premenných, nachádza sa v najvyššej úrovni aplikácie
export const AppContext = createContext<AppContextModel>({

});
