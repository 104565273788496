import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Modely
import { Credit } from '../../models/Models';
import { AppContext } from '../../AppContext';

// Utility
import { format as dateFormat } from 'date-fns';
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { Alert, Backdrop, Button, CircularProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { ContentSimple, ContentTitle } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Tabuľka - štýl
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: '2px solid #333333'
    },
    [`&.${tableCellClasses.body}`]: {

    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface CreditSettings {
    creditPrice?: number;
    creditTitle?: string;
}

const Credits = () => {

    // Všeobecne
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const context = useContext(AppContext);
    const [settings, setSettings] = useState<CreditSettings>({});
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Tabuľka
    const [rows, setRows] = useState<Credit[]>([]);
    const rowsPageSize: number = 10;
    const [rowsPageIndex, setRowsPageIndex] = useState<number>(rowsPageSize);

    // Načtať kompletné dáta
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'credit')
            .then(response => {
                setRowsPageIndex(rowsPageSize);
                if (response.data !== null) {
                    setRows(response.data.list as Credit[]);
                    setSettings(response.data.settings as CreditSettings);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Presmerovanie na platobnú bránu
    const handleBuy = () => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Kúpiť kredit: ' + FormatDecimal(settings.creditPrice ?? 0, 2) + ' EUR',
            children: <>
                Kliknutím na tlačidlo "áno" potvrdzujete, že ste sa oboznámili s <Link sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }} href={AppConfig.UrlTerms} target="_blank">obchodnými podmienkami</Link> a súhlasíte s povinnosťou platby.
            </>,
            onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'credit/buy')
                    .then(response => {
                        if (response.data) {

                            // Presmerovanie na platobnú bránu
                            if (response.data.success === true) {
                                window.location.href = response.data.data;
                                return;
                            }

                            // Len pre prihlásených
                            if (response.data.data === 'unauthorized') {
                                // @ts-ignore
                                window.appOpenLogin?.();
                                alert('Ľutujeme, ale kredit si môže kúpiť len prihlásený užívateľ!\nNajskôr sa prosím prihláste do svojho účtu.');
                                return;
                            }

                            // Len pre užívateľov (nie admin, ani tréner)
                            if (response.data.data === 'badrole') {
                                alert('Zakúpenie kreditu je povolené len pre bežných užívateľov!');
                                return;
                            }

                            // Neznáma chyba
                            alert('Ľutujeme, ale nebolo možné vytvoriť žiadosť o platbu.\nNastala neznáma chyba, prosím kontaktujte nás!');
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    return (
        <ContentSimple>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Nadpis */}
            <ContentTitle title={FormatDecimal(context.userInfo?.credit ?? 0, 2) + ' EUR'} subtitle="Aktuálny zostatok" />

            {/* Kúpiť kredit */}
            <Button variant="contained" size="large" color="info" startIcon={<ShoppingCartIcon />} onClick={() => { handleBuy(); }}>Kúpiť kredit: {FormatDecimal(settings.creditPrice ?? 0, 2)} EUR</Button>
            {(settings.creditTitle?.length ?? 0) > 0 && <Alert severity='info' sx={{ mt: 2 }}>{settings.creditTitle}</Alert>}

            {/* Pohyby */}
            {rows.length > 0 && (
                <>
                    <Typography variant='h5' sx={{ mt: 3, mb: 2 }}>História kreditu</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ paddingRight: 0, width: 200 }}>
                                        Vytvorené<br />
                                        Stav
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ paddingRight: 0 }}>
                                        Rezervácia<br />
                                        Poznámka
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ width: 100, textAlign: 'right' }}>Suma</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => index < rowsPageIndex ? (
                                    <StyledTableRow key={row.id} sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        ...((row.storno ?? false) === true ? { textDecoration: 'line-through', opacity: .3 } : {})
                                    }}>
                                        <StyledTableCell sx={{ paddingRight: 0 }} component="th" scope="row">
                                            {(new Date(row.createdDate ?? '').getFullYear() > 1 ? dateFormat(new Date(row.createdDate ?? ''), 'dd.MM.yyyy HH:mm') : '-')}<br />
                                            {(row.storno ?? false) === true ? 'Stornované' : 'Zaúčtované'}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ paddingRight: 0 }}>
                                            {(row.bookingId ?? 0) > 0 ? '#' + row.bookingId + ', ' + row.bookingName + ', ' + dateFormat(new Date(row.bookingDate ?? '2000'), 'dd.MM.yyyy HH:mm') : '-'}<br />
                                            {(row.price ?? 0) > 0 ? 'Platnosť do: ' + dateFormat(new Date(row.expirationDate ?? ''), 'dd.MM.yyyy HH:mm') + '. ' : ''} {(row.paymentId ?? 0) > 0 ? 'Identifikátor platby: #' + row.paymentId + '.' : ''} {row.note} 
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', paddingLeft: 0, color: (row.price ?? 0) < 0 ? 'red' : 'green' }}>
                                            {((row.price ?? 0) > 0 ? '+' : '') + FormatDecimal(row.price ?? 0, 2)} EUR
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ) :
                                    (
                                        <React.Fragment key={row.id}></React.Fragment>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {rows.length > rowsPageIndex && <Button fullWidth sx={{ mt: 1, mb: 3 }} variant='contained' color='primary' onClick={() => setRowsPageIndex(prev => prev + rowsPageSize)}>Zobraziť viac</Button>}
                </>)}
        </ContentSimple>
    )
}

export default Credits;
