import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Convert';

// Modely
import { Credit } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import skLocale from 'date-fns/locale/sk';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import { NullMinDate } from '../../utility/Date';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    severity: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    severity: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Credit => ({
    id: 0,
    note: ''
});

// Vstupné parametre
export interface UserDetailCreditsAddProps {
    userId: number;
    open: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const UserDetailCreditsAdd = (props: UserDetailCreditsAddProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Credit>(EmptySource());

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        setSource(EmptySource());
    }, [props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        // Pridám užívateľa
        var data = { ...source, userId: props.userId } as Credit;

        axios
            .post(AppConfig.ApiUri + 'credit', data)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť!' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(_e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ user: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Vložiť kredit
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="price" type="text" label="Suma" variant="outlined" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth required>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Platnosť do" value={NullMinDate(source.expirationDate)} onChange={(d) => { handleChange('expirationDate', d) }} slotProps={{ textField: { required: true } }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="note" label='Poznámka' fullWidth variant="outlined" autoComplete="off" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.severity as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog >
        </>
    )
}

export default UserDetailCreditsAdd;