import { createStyles, makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: any) => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%',
            cursor: 'default',
            backgroundColor: '#ececec!important',
            background: 'url(/assets/body.jpg) top center no-repeat'
        },
        a: {
            textDecoration: 'none'
        },
        '#root': {
            height: '100%',
            width: '100%'
        },

        /* HTML Popis */
        '.html-description ul': {
            paddingLeft: '30px'
        },
        '.html-description ol': {
            paddingLeft: '30px'
        },
        '.html-description p': {
            marginBlockStart: '0.5em',
            marginBlockEnd: '0.5em'
        },

        /* Calendar */
        '.fc .fc-toolbar.fc-header-toolbar': {
            marginBottom: '0.3rem'
        },

        /* Scrollbar */
        '*::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.8em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#666666'
        },

        /* Form */
        '.formIconHelp': {
            cursor: 'help'
        },

        /* Components > Navigation */
        '.navigation-collapse-child': {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '5px',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                content: '""',
                background: theme.layout.sidebar.collapse.borderColor,
            }
        },

        /* Components > Dialog */
        '& .MuiDialog-container .MuiDialog-paper': {
            '& .MuiDialogContent-root': {
                paddingTop: 0,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-container .MuiDialog-paper': {
                width: '100%',
                maxWidth: '98%',
                margin: '10px 0',
                '& .MuiDialogContent-root': {
                    padding: '5px 10px',
                },
                '& .MuiDialogActions-root': {
                    padding: '10px 10px',
                }
            },
        },

        /* Components > DataGrid */
        '.MuiDataGrid-booleanCell[data-value="false"]': {
            opacity: .3
        },

        /* Components > ButtonList */
        '.buttonlist-collapse-child': {
            position: 'relative',
            '&:before': {
                display: 'block',
                width: '5px',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                content: '""',
                background: theme.palette.secondary.light,
            }
        },

        /* FormControls */
        '.MuiFilledInput-root': {
            background: "white"
        },
        '.MuiFilledInput-root:hover': {
            background: "whitesmoke"
        }

    },
}));

const StylesGlobal = () => {
    const theme = useTheme();
    useStyles(theme);
    return null;
};

export default StylesGlobal;