import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility

// Modely
import { User } from '../../models/Models';

// Komponenty
import { Box, Button, Drawer, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import UserDetailCreditsAdd, { UserDetailCreditsAddProps } from './UserDetailCreditsAdd';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import UserDetailCredits from './UserDetailCredits';
import { FormatDecimal } from '../../utility/Format';
import UserDetailBookings from './UserDetailBookings';

interface UserDetailProps {
    id: number;
    keepMounted?: boolean;
    onChanged?: () => void;
    onEdit?: (copy?: boolean) => void;
    onDelete?: (name: string) => void;
    onClose: () => void;
}

const UserDetail = (props: UserDetailProps) => {

    // Stav
    const [loading, setLoading] = useState<boolean>(true);
    const [source, setSource] = useState<User>({});
    const [tabValue, setTabValue] = useState<string>('booking');

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        if (props.id === 0) {
            setSource({});
            setTabValue('booking');
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user/detail/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as User);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [props.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vložiť kredit
    const [userDetailCreditsSaved, setUserDetailCreditsSaved] = useState<boolean>(false);
    const [userDetailCreditsAdd, setUserDetailCreditsAdd] = useState<UserDetailCreditsAddProps>({
        userId: 0,
        open: false,
        onSave: () => setUserDetailCreditsSaved(true),
        onClose: () => setUserDetailCreditsAdd(prev => ({ ...prev, open: false }))
    });

    // Načítam dáta po uložení kreditu
    useEffect(() => {
        if (userDetailCreditsSaved === true) {
            setUserDetailCreditsSaved(false);
            loadData();
            return;
        }
    }, [userDetailCreditsSaved]);

    return (
        <>
            {/* Vložiť kredit */}
            <UserDetailCreditsAdd {...userDetailCreditsAdd} userId={props.id ?? 0} />

            <Drawer keepMounted={props.keepMounted ?? false} anchor="right" open={props.id > 0} onClose={props.onClose} PaperProps={{ sx: { width: '100%', maxWidth: '600px', p: 0, pt: 0 } }}>
                <Box sx={{ position: 'sticky', top: 0, pt: 2, p: 2, pb: 1, background: 'white', zIndex: 9999, borderBottom: '1px solid #F0F0F0' }}>
                    <Box sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                        {props.onEdit !== undefined && (<IconButton aria-label="edit" onClick={() => props.onEdit?.(false)} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><EditIcon /></IconButton>)}
                        {props.onDelete !== undefined && (<IconButton aria-label="delete" onClick={() => props.onDelete?.(source.name ?? '')} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><DeleteIcon /></IconButton>)}
                        <IconButton aria-label="close" onClick={() => props.onClose()} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><CloseIcon /></IconButton>
                    </Box>
                    <Typography mb={1} variant="h5" sx={{ pr: '130px' }}>{loading === true ? 'Čakajte...' : source?.name}</Typography>
                </Box>
                <Box sx={{ px: 2 }}>
                    <Box sx={{ mt: 1, position: 'relative' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            <Tab value="booking" label={'Rezervácie (' + (source.bookingsTotal ?? 0) + ')'} />
                            <Tab value="credit" label={'Kredit (' + FormatDecimal((source.creditsTotal ?? 0), 2) + ' EUR)'} />

                        </Tabs>
                        <Button onClick={() => {
                            setTabValue('credit');
                            setUserDetailCreditsAdd(prev => ({ ...prev, open: true }));
                        }} sx={{ position: 'absolute', top: '5px', right: '0px' }} variant="contained">Vložiť kredit</Button>
                    </Box>
                    <TabContext value={tabValue}>
                        <TabPanel value="booking" sx={{ px: 0 }}>
                            <UserDetailBookings rows={source.bookings ?? []} />
                        </TabPanel>
                        <TabPanel value="credit" sx={{ px: 0 }}>
                            <UserDetailCredits rows={source.credits} />
                        </TabPanel>
                    </TabContext>

                </Box>
            </Drawer>
        </>
    )
}

export default UserDetail