
// Modely
import { EventLite } from '../../models/Models';

// Utility
import { format as dateFormat } from 'date-fns';
import parseHtml from 'html-react-parser';

// Komponenty
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

// Ikony
import PersonIcon from '@mui/icons-material/Person';

interface ScheduleItemProps {
    item: EventLite;
    connector: boolean;
    onCreate: (booking: EventLite) => void;
    onCancel: (booking: EventLite) => void;
}

const ScheduleItem = (props: ScheduleItemProps) => {

    return (
        <TimelineItem sx={{ ...((props.item.cancelled ?? false) === true ? { textDecoration: 'line-through', opacity: .3 } : {}) }}>

            {/* Ľava časť - čas */}
            <TimelineOppositeContent color="black" sx={{ mt: -1 }}>
                {/* Čas  */}
                <Typography variant='h4'>{dateFormat(new Date(props.item.date ?? new Date()), 'HH:mm')}</Typography>

                {/* Dátum a názov dňa */}
                <Typography variant='body2' color="textSecondary">
                    {dateFormat(new Date(props.item.date ?? new Date()), 'dd.MM.yyyy')}<br />
                    {props.item.dateDay}
                </Typography>
            </TimelineOppositeContent>

            {/* Oddeľovač */}
            <TimelineSeparator>
                <TimelineDot />
                {props.connector && <TimelineConnector />}
            </TimelineSeparator>

            {/* Pravá časť  */}
            <TimelineContent sx={{ mt: -1, pb: 7 }}>

                {/* Názov a info  */}
                <Typography variant='h4'>
                    {/* Názov */}
                    {props.item.name}&nbsp;

                    {/* Zrušené  */}
                    {(props.item.cancelled ?? false) === true && <Chip label="Zrušené" color="primary" size="small" />}

                    {/* Rezervované  */}
                    {(props.item.bookingCreated ?? false) === true && <Chip label="Rezervované" color="info" size="small" />}
                </Typography>

                {/* Tréner  */}
                <Chip
                    sx={{ my: 1 }}
                    label={props.item?.coach?.name}
                    avatar={(
                        props.item.coach?.fileIsImage ?
                            <Avatar sx={{ width: 48, height: 48 }} alt={props.item.coach?.name} src={props.item.coach?.fileSrcSmall} /> :
                            <Avatar sx={{ width: 32, height: 32 }}><PersonIcon /></Avatar>
                    )}
                />

                {/* Popis rezervácie */}
                <Box sx={{ pl: 1 }}>
                    <Typography component='span' variant='body2'>
                        {parseHtml(props.item.bookingInfotext ?? '')}
                    </Typography>
                </Box>

                {/* Vytvoriť rezerváciu */}
                {(props.item.bookingCreatable ?? false) === true && (props.item.bookingCreated ?? false) === false && <Button sx={{ mt: 1 }} variant='contained' color='success' onClick={() => props.onCreate(props.item)}>Rezervovať termín</Button>}

                {/* Zrušiť rezerváciu */}
                {(props.item.bookingCancellable ?? false) === true && (props.item.bookingCreated ?? false) === true && <Button sx={{ mt: 1 }} variant='contained' color='error' size="small" onClick={() => props.onCancel(props.item)}>Zrušiť rezerváciu</Button>}

            </TimelineContent>

        </TimelineItem>
    )
}

export default ScheduleItem;