import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Setting } from '../../models/Models';

// Utility

// Komponenty
import { Backdrop, Button, CircularProgress, Grid } from '@mui/material';
import { Content, ContentPanel, ContentMain } from '../../layout/Content';
import TinyMCE from '../../components/TinyMCE';

// Ikony
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';

const Notice = () => {

    // Typ nastavenia: 1
    const settingType: number = 1;

    // Všeobecne
    const [loading, setLoading] = React.useState(false);
    const [content, setContent] = useState<string>();

    // Načtať údaje
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'setting/' + settingType)
            .then(response => {
                if (response.data !== null) {
                    setContent((response.data as Setting).value ?? '');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Uložiť oznam
    const handleSave = () => {
        setLoading(true);
        // Nastavenie č.1 = oznam
        var setting: Setting = { type: settingType, value: content };
        axios
            .post(AppConfig.ApiUri + 'setting', setting)
            .then(response => {
                alert(response.data === true ? 'Uložené!' : 'Záznam sa nepodarilo uložiť!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Content invert={true}>
                <ContentPanel>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Button variant="contained" sx={{ mr: 1 }} size="large" color="info" startIcon={<SaveIcon />} onClick={handleSave}>Uložiť oznam</Button>
                        </Grid>
                    </Grid>
                </ContentPanel>
                <ContentMain>
                    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                        <TinyMCE height="100%" onChange={(s) => setContent(s)} content={content ?? ''} contentStyle='body { padding: 5px 20px; }' />
                    </div>
                </ContentMain>
            </Content>
        </>
    )
}

export default Notice;
