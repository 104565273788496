import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Alert, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { CookieCreate } from '../../utility/Cookies';
import { AppConfig } from '../../AppConfig';

// Modely
import { Authorization } from '../../models/Models';

// Ikony
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    onPasswordRecovery: () => void;
    onAccountCreate: () => void;
    onLogged: () => void;
    onClose: () => void;
}

const Login = (props: Props) => {

    // Stav
    const [loading, setLoading] = React.useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loggedState, setLoggedState] = useState(0);
    const [remember, setRemember] = useState<boolean>(true);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        setLoggedState(0);

        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString() ?? '';
        const emailIsValid = /\S+@\S+\.\S+/.test(email);
        const password = data.get('password')?.toString() ?? '';
        const passwordIsValid = password.length > 0;
        const remember = ((data.get('remember')?.toString() ?? 'false') === 'true');

        setEmailError(!emailIsValid);
        setPasswordError(!passwordIsValid)

        if (!emailIsValid || !passwordIsValid) {
            setLoading(false);
            return;
        }

        axios
            .post(AppConfig.ApiUri + 'user/login',
                {
                    email: email,
                    password: password,
                    remember: remember
                } as Authorization
            )
            .then(response => {
                if (response.data.message === 'error') {
                    setLoading(false);
                    setLoggedState(1);
                    return;
                }
                if (response.data.message === 'blacklist') {
                    setLoading(false);
                    setLoggedState(2);
                    return;
                }
                if (response.data.session !== '') {
                    CookieCreate(AppConfig.CookieName.LoggedSession, response.data.session, 1);
                }
                if (response.data.sessionRemember !== '') {
                    CookieCreate(AppConfig.CookieName.LoggedSessionRemember, response.data.sessionRemember, 365);
                }
                props.onLogged();
                setLoading(false);
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog keepMounted={false} maxWidth="xs" fullWidth open={true} scroll="body" onClose={(_e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Prihlásenie</DialogTitle>
                    <IconButton aria-label="close" onClick={props.onClose} sx={{ position: 'absolute', right: 8, top: 8, opacity: .8, background: 'whitesmoke' }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Grid container columnSpacing={1}>

                            <Grid item xs={12}>
                                <TextField margin="normal" error={emailError} required fullWidth label="Email" name="email" autoComplete="email"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="normal" error={passwordError} required fullWidth name="password" label="Heslo" type="password" autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox size="small" value={remember} onChange={(e) => { setRemember(e.target.checked); }} name="remember" color="primary" />} checked={remember} label='Zostať trvale prihlásený' />
                            </Grid>

                            <Grid item xs={12}>
                                <Button type="submit" disabled={loading} sx={{ mt: 1, mb: 1 }} fullWidth size="large" variant="contained">Prihlásiť</Button>
                                <Button type="button" disabled={loading} sx={{ mb: 1 }} fullWidth size="large" variant="outlined" onClick={props.onClose}>Zrušiť</Button>
                                {(loggedState === 1 && <Alert sx={{ mb: 1 }} severity="warning">Nesprávne prístupové údaje!</Alert>)}
                                {(loggedState === 2 && <Alert sx={{ mb: 1 }} severity="error">Prihlásenie je na niekoľko minút zablokované!</Alert>)}
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ p: 3, pt: 0 }}>
                        <Box sx={{ display: 'inline-flex', marginRight: 'auto' }}>
                            <Link href="/" sx={{ mr: 2 }} onClick={(e) => { props.onAccountCreate(); e.preventDefault(); }}>Nemáte ešte účet?</Link>
                            <Link href="/" sx={{ mr: 2 }} onClick={(e) => { props.onPasswordRecovery(); e.preventDefault(); }}>Zabudli ste heslo?</Link>
                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>

        </>
    )
}

export default Login;