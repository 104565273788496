import axios from 'axios';
import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import { AppConfig } from '../../AppConfig';
import { CookieErase, CookieRead } from '../../utility/Cookies';
import LogoutIcon from '@mui/icons-material/Logout';

const Logout = () => {

    const handleLogout = () => {
        axios
            .post(AppConfig.ApiUri + 'user/logout', null, {
                headers: {
                    'session': CookieRead(AppConfig.CookieName.LoggedSession) ?? '',
                    'sessionRemember': CookieRead(AppConfig.CookieName.LoggedSessionRemember) ?? ''
                }
            })
            .then(() => {
                CookieErase(AppConfig.CookieName.LoggedSession);
                CookieErase(AppConfig.CookieName.LoggedSessionRemember);
                window.location.href = '/';
            });
    };

    return (
        <MenuItem dense onClick={handleLogout}>
            <ListItemIcon><LogoutIcon color="error" fontSize="small" /></ListItemIcon> <Typography variant="body2" color="error">Odhlásiť</Typography>
        </MenuItem>
    )
}

export default Logout;