import axios from 'axios';
import { AppConfig } from '../../AppConfig';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utility/URL';
import { CookieCreate } from '../../utility/Cookies';

interface Verification {
    verify: number;
    token: string;
}

interface AccountVerifyProps {

}

const AccountVerify = (props: AccountVerifyProps) => {

    // Stav
    const [verification, setVerification] = useState<Verification>({ verify: 0, token: '' });

    // Overenie registrácie podľa URL
    const history = useHistory();
    const requestVerify: number = parseInt(useQuery().get('verify') ?? '0');
    const requestToken: string = useQuery().get('token') ?? '';

    // Ak url obsahuje overovacie hodnoty, tak ich vložím do stavu čím vyvolám kontrolu
    useEffect(() => {
        if (requestVerify === 0 || requestToken.length === 0) {
            return;
        }
        setVerification({ verify: requestVerify, token: requestToken });
    }, [requestVerify, requestToken]); // eslint-disable-line react-hooks/exhaustive-deps

    // Po zmene stavu automaticky odošlem hodnoty pre overenie
    useEffect(() => {
        if (verification.verify === 0 || verification.token.length === 0) {
            return;
        }
        axios
            .post(AppConfig.ApiUri + 'user/account/verify', null, {
                params: {
                    'verify': verification.verify,
                    'token': verification.token
                }
            })
            .then(response => {
                if (response.data && response.data.message === 'success') {
                    if (response.data.session !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSession, response.data.session, 1);
                    }
                    if (response.data.sessionRemember !== '') {
                        CookieCreate(AppConfig.CookieName.LoggedSessionRemember, response.data.sessionRemember, 365);
                    }
                    alert('Registrácia bola úspešne dokončená! Teraz budete automaticky prihlásený.');
                    history.push('/');
                    window.location.reload();
                }
            });
    }, [verification]);

    return null;
}

export default AccountVerify;