import { useEffect, useRef, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { UserFilter, UserLite } from '../../models/Models';

// Utility
import { Export as DataGridExport, Settings as DataGridSettings, LocalizeGrid } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { format as dateFormat } from 'date-fns';
import Debounce from '../../utility/Debounce';
import { ConvertToInt } from '../../utility/Convert';

// Komponenty
import { DataGrid, GridColDef, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridSortModel, GridDensity } from "@mui/x-data-grid";
import { Avatar, Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, Select, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import { Content, ContentPanel, ContentMain } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import UserCreate, { UserCreateProps } from './UserCreate';
import UserDetail from './UserDetail';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import { FormatDecimal } from '../../utility/Format';

// Komponent pre zoznam
const Users = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [detailId, setDetailId] = useState<number>(0);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [userCreate, setUserCreate] = useState<UserCreateProps>({
        open: false,
        keepMounted: true,
        onSave: () => loadDataSafe(),
        onClose: () => setUserCreate(prev => ({ ...prev, open: false }))
    });
    const refSearch = useRef(null);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridSettings({ uid: 'users' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívny', hide: false, width: 70, type: 'boolean' },
        { field: 'coach', headerName: 'Tréner', hide: false, width: 70, type: 'boolean' },
        { field: 'admin', headerName: 'Administrátor', hide: false, width: 70, type: 'boolean' },
        {
            field: 'file', headerName: 'Fotka', hide: false, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.fileIsImage ?
                        <Avatar alt={params.row.name} src={params.row.fileSrcSmall} /> :
                        <Avatar><PersonIcon /></Avatar>}
                </>
            )
        },
        { field: 'name', headerName: 'Meno', hide: false, minWidth: 50, flex: 0.8 },
        { field: 'email', headerName: 'Email', hide: false, minWidth: 50, flex: 0.8 },
        { field: 'phone', headerName: 'Telefón', hide: false, minWidth: 50, flex: 0.8 },
        { field: 'note', headerName: 'Poznámka', hide: false, minWidth: 50, flex: 0.8 },
        {
            field: 'credit', headerName: "Kredit", hide: false, minWidth: 60, sortable: false, flex: 0.4, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? dateFormat((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 180, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label='Upraviť' title='Upraviť (enter)' size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label='Kopírovať' title='Kopírovať (ctrl + enter)' size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label='Vymazať' title='Vymazať (delete)' size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    <Button size="small" variant="contained" sx={{ ml: 1 }} onClick={() => handleDetail(params.value ?? 0)}>Info</Button>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<UserLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFilterMenuEl, setRowsFilterMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<UserFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25) });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = 'name';
        let sortAsc: boolean = true;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field;
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setUserCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Zobrazenie detailu (postranný panel)
    const handleDetail = (id: number) => setDetailId(id);

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.USERS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Detail záznamu podľa "id" v URL
    const requestDetailId: number = parseInt(useQuery().get('detailId') ?? '0');
    useEffect(() => {
        if (requestDetailId > 0) {
            history.push(AppRouteUrl.USERS);
            handleDetail(requestDetailId);
        }
    }, [requestDetailId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'user/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'user/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExportCSV = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'csv',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    const handleExportXML = () => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: 'xml',
            columns: columns,
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.type ?? 0) !== 0) { count++; }
        return count;
    };
    const filterClear = (): void => {
        setRowsFilter(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            sortAsc: prev.sortAsc
        }));
    }

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <UserCreate {...userCreate} />

            {/* Obsah */}
            <Content>
                <ContentPanel>
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={7} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" color="info" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový záznam</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Označené {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '55px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExportCSV(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Uložiť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExportXML(); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Uložiť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }} ref={refSearch}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => filterClear()}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setRowsFilterMenuEl(refSearch.current ?? null)}
                            />
                        </Grid>

                        {/* Filter */}
                        <Menu slotProps={{ paper: { style: { width: 300 } } }} anchorEl={rowsFilterMenuEl} open={Boolean(rowsFilterMenuEl)} onClose={() => setRowsFilterMenuEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Box sx={{ px: 1, m: 0 }}>
                                <Typography variant="h5" my={2} textAlign="center">Filter</Typography>
                                
                                <FormControl margin="dense" size="small" variant="outlined" fullWidth>
                                    <InputLabel>Typ užívateľa</InputLabel>
                                    <Select label="Typ užívateľa" fullWidth sx={{ verticalAlign: 'middle' }} size="small" value={rowsFilter.type ?? 0} name="type"
                                        onChange={(e) => {
                                            setRowsFilter(prev => ({ ...prev, page: 0, type: ConvertToInt(e.target.value) }));
                                            setRowsFilterMenuEl(null);
                                        }}>
                                        <MenuItem value={0}>-</MenuItem>
                                        <MenuItem value={1}>Tréner</MenuItem>
                                        <MenuItem value={2}>Administrátor</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Menu>

                    </Grid>
                </ContentPanel>
                <ContentMain>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick

                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={LocalizeGrid()}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleCreate(e.row.id, false, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if (c.code === 'Enter' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentMain>
            </Content>

            {/* Detail užívateľa  */}
            <UserDetail id={detailId}
                onChanged={() => loadData()}
                onClose={() => setDetailId(0)}
                onEdit={(copy?: boolean) => { handleCreate(detailId, copy); setDetailId(0); }}
                onDelete={(name: string) => { handleDelete(detailId, name); setDetailId(0); }} />
        </>
    )
}

export default Users;