import { useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { format as dateFormat } from 'date-fns';
import { NullMinDate } from '../../utility/Date';

// Modely

// Komponenty
import { Backdrop, Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import skLocale from 'date-fns/locale/sk';

// Ikony
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

interface StatisticsPanelProps {
    dateFrom?: Date;
    dateTo?: Date;
    onChangeDateFrom: (d?: Date) => void;
    onChangeDateTo: (d?: Date) => void;
    onShow?: () => void;
}

const StatisticsPanel = (props: StatisticsPanelProps) => {

    // Všobecne
    const [loading, setLoading] = useState<boolean>(false);

    // Exportovať údaje
    const handleExport = () => {
        if (NullMinDate(props.dateFrom) === null) {
            alert('Musíte vyplniť obdobie od!');
            return;
        }
        if (NullMinDate(props.dateTo) === null) {
            alert('Musíte vyplniť obdobie do!');
            return;
        }

        setLoading(true);

        // Názov súboru
        const exportFileName = 'Štatistiky (' + dateFormat(props.dateFrom ?? new Date(), 'dd.MM.yyyy') + '-' + dateFormat(props.dateTo ?? new Date(), 'dd.MM.yyyy') + ').xlsx';

        // Stiahnem cez AXIOS (kvôli session)
        axios
            .get(AppConfig.ApiUri + 'statistic/export', {
                params: {
                    from: props.dateFrom,
                    to: props.dateTo
                },
                responseType: 'blob'
            })
            .then(response => {
                if (response.data !== null) {

                    // Vytvorenie URL objektu pre sťahovanie súboru
                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    // Vytvorenie <a> elementu pre sťahovanie súboru
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', exportFileName); // Nastavenie názvu súboru, môžete si ho prispôsobiť

                    // Simulácia kliknutia na link pre sťahovanie súboru
                    document.body.appendChild(link);
                    link.click();

                    // Po stiahnutí súboru odstrániť vytvorený URL objekt
                    window.URL.revokeObjectURL(url);
                }
            })
            .finally(() => { setLoading(false); });
    }

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                        <DatePicker
                            format='dd.MM.yyyy' label="Obdobie od" value={NullMinDate(props.dateFrom)} onChange={(d) => { props.onChangeDateFrom(d ?? undefined) }}
                            slotProps={{ textField: { size: "small", fullWidth: true, variant: 'outlined' } }} />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                        <DatePicker
                            format='dd.MM.yyyy' label="Obdobie od" value={NullMinDate(props.dateTo)} onChange={(d) => { props.onChangeDateTo(d ?? undefined) }}
                            slotProps={{ textField: { size: "small", fullWidth: true, variant: 'outlined' } }} />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={props.onShow} fullWidth size='medium' color='info' variant='contained' startIcon={<SearchIcon />}>Zobraziť</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleExport} fullWidth size='medium' color='primary' variant='contained' startIcon={<SaveIcon />}>Exportovať</Button>
                </Grid>
            </Grid>
        </>
    )
}

export default StatisticsPanel;