import React from 'react';
import clsx from "clsx";

// Komponenty
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Theme, createTheme } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import { FormatDecimal } from '../utility/Format';

// Progressbar v grid: https://stackoverflow.com/questions/69467236/material-ui-datagrid-linear-progress-bar-in-column-cells
const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                border: `1px solid ${theme.palette.divider}`,
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: 26,
                borderRadius: 2
            },
            value: {
                position: "absolute",
                lineHeight: "24px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                color: 'black'
            },
            bar: {
                height: "100%",
                "&.low": {
                    backgroundColor: "#f44336"
                },
                "&.medium": {
                    backgroundColor: "#efbb5aa3"
                },
                "&.high": {
                    backgroundColor: "#088208a3"
                }
            }
        }),
    { defaultTheme }
);
interface ProgressBarProps {
    value: number;
    label?: string;
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
    const valueInPercent = props.value * 100;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.value}>{(props.label ?? '').length > 0 ? props.label : `${FormatDecimal(valueInPercent, 0)} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70
                })}
                style={{ maxWidth: `${valueInPercent}%` }}
            />
        </div>
    );
});
export function RenderProgressBar(params: GridRenderCellParams, label?: string) {
    return <ProgressBar value={Number(params.value)!} label={label} />;

}
