import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../utility/Convert';

// Modely
import { FileItem, User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControlLabel, Switch, Card, CardMedia, CardActions, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FileUpload from '../../components/FileUpload';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    severity: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    severity: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): User => ({
    id: 0,
    actived: true,
    admin: false,
    coach: false,
    email: '',
    name: '',
    password: '',
    phone: '',
    note: '',
    fileName: ''
});

// Vstupné parametre
export interface UserCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const UserCreate = (props: UserCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<User>(EmptySource());
    const [sourcePasswordVerify, setSourcePasswordVerify] = useState<string | undefined>(undefined);
    const [avatars, setAvatars] = useState<FileItem[]>([]);

    const loadAvatars = () => {
        axios
            .get(AppConfig.ApiUri + 'user/avatar/')
            .then(response => setAvatars(response.data));
    };
    useEffect(() => loadAvatars(), []);

    // Vložiť nahrané súbory
    const handleFileUpload = (files: FileItem[]) => {
        if (files.length === 0) {
            return;
        }
        setSource(prev => ({
            ...prev,
            fileName: files[0].fileName,
            fileSrc: files[0].fileSrc,
            fileSrcSmall: files[0].fileSrcSmall,
            fileSrcMedium: files[0].fileSrcMedium
        }));
    };

    // Vložiť predpripravené fotky 
    const [avatarIndex, setAvatarIndex] = useState<number>(0);
    const nextAvatar = () => {
        if (avatars.length === 0 || avatars.length < avatarIndex) {
            return;
        }
        setSource(prev => ({
            ...prev,
            fileName: avatars[avatarIndex].fileName,
            fileSrc: avatars[avatarIndex].fileSrc,
            fileSrcSmall: avatars[avatarIndex].fileSrcSmall,
            fileSrcMedium: avatars[avatarIndex].fileSrcMedium
        }));
        setAvatarIndex(prev => (prev >= 9 ? 0 : prev + 1));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        setSourcePasswordVerify('');
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as User);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: 'Musíte vyplniť: celé meno!' });
            return;
        }
        if (source.email === '') {
            setWarning({ ...EmptyWarning, name: 'email', text: 'Musíte vyplniť: email!' });
            return;
        }

        if ((source.password?.length ?? 0) > 0) {
            const password = source.password ?? '';
            const passwordVerify = sourcePasswordVerify ?? '';
            if (password !== passwordVerify) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslá sa nezhodujú!' });
                return;
            }
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť!' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => input?.focus(), 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(_e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ user: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 && 'Nový: ')} Užívateľ
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived ?? false} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Aktívny' />
                                <FormControlLabel control={<Switch checked={source.coach ?? false} name="coach" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Tréner' />
                                <FormControlLabel control={<Switch checked={source.admin ?? false} name="admin" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Administrátor' />
                            </Grid>

                            {(source.actived ?? false) === false && <>
                                <Grid item xs={12} mb={1}>
                                    <Alert severity='info'>Neaktívny užívateľ má zablokované prihlásenie</Alert>
                                </Grid>
                            </>}

                            <Grid item xs={12} md container columnSpacing={1}>

                                <Grid item xs={8}>
                                    <TextField required margin="dense" name="name" label='Celé meno' fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                        inputProps={{ maxLength: 255 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField fullWidth margin="dense" name="birthYear" type="number" label="Rok narodenia" variant="outlined" value={(source.birthYear ?? 0) > 0 ? source.birthYear : ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                        inputRef={input => props.autoFocus === 'birthYear' && setFocus(input)}
                                        inputProps={{ min: 1900, max: 2200 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EventIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={8}>
                                    <TextField required margin="dense" name="email" type="email" label='Email' fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'email') && setFocus(input)}
                                        inputProps={{ maxLength: 255 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField margin="dense" name="phone" type="phone" label='Telefón' fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'phone') && setFocus(input)}
                                        inputProps={{ maxLength: 50 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField margin="dense" name="street" type="street" label='Ulica a číslo domu' fullWidth variant="outlined" autoComplete="off" value={source.street ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'street') && setFocus(input)}
                                        inputProps={{ maxLength: 50 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PlaceIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField margin="dense" name="city" type="city" label='Mesto' fullWidth variant="outlined" autoComplete="off" value={source.city ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'city') && setFocus(input)}
                                        inputProps={{ maxLength: 50 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PlaceIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField margin="dense" name="postcode" type="postcode" label='PSČ' fullWidth variant="outlined" autoComplete="off" value={source.postcode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'postcode') && setFocus(input)}
                                        inputProps={{ maxLength: 15 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PlaceIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" error={warning.name === 'password'} name="password" type="password" label='Heslo' fullWidth variant="outlined" autoComplete="new-password" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputProps={{ maxLength: 70 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" error={warning.name === 'password'} name="passwordverify" type="password" label='Heslo znovu' fullWidth variant="outlined" autoComplete="new-password" value={sourcePasswordVerify ?? ''} onChange={(e => { setSourcePasswordVerify(e.target.value); })}
                                        inputProps={{ maxLength: 70 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} md={3} container sx={{ ml: { sx: 0, md: 1 } }}>

                                <Grid item xs={12}>
                                    <Card sx={{ mt: 1 }}>
                                        {source?.fileName?.length ? (
                                            <CardMedia component="img" height="144" image={source?.fileSrcMedium ?? ''} />
                                        ) : (
                                            <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '144px' }, justifyContent: 'center', alignItems: 'center' }}>
                                                <PersonIcon sx={{ color: 'silver', fontSize: 100 }} />
                                            </CardMedia>
                                        )}
                                        <CardActions disableSpacing sx={{ padding: 1 }}>
                                            <FileUpload onUploaded={handleFileUpload} label='Vložiť' multiple={false} acceptedExtensions={['jpg', 'jpeg', 'png', 'gif', 'bmp']} />
                                            <IconButton aria-label="next" onClick={() => nextAvatar()} size="small">
                                                <ReplayIcon fontSize='small' />
                                            </IconButton>
                                            {(source?.fileName?.length ?? 0) > 0 &&
                                                <IconButton aria-label="delete" onClick={() => { setSource(prev => ({ ...prev, fileName: undefined })) }} size="small">
                                                    <DeleteOutlinedIcon fontSize='small' />
                                                </IconButton>
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                </Grid>

                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label='Interná poznámka' variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 1000 }}
                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.severity as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog >
        </>
    )
}

export default UserCreate;