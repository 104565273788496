import { AppContextUser } from './AppContext';

// Podstránky
import Schedule from './views/schedule/Schedule';
import Today from './views/today/Today';
import Events from './views/event/Events';
import Users from './views/user/Users';
import Notice from './views/notice/Notice';
import Bookings from './views/booking/Bookings';
import Credits from './views/credit/Credits';
import Settings from './views/settings/Settings';
import Templates from './views/templates/Templates';
import Statistics from './views/statistic/Statistics';

// Pomôcky: 
// https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
// https://www.youtube.com/watch?v=J6jzDfHoj-Y&ab_channel=TheNerdyCanuck

// Zoznam všetkých URL adries
export enum AppRouteUrl {

    HOME = '/',

    // Užívateľ
    CREDIT = '/credit/',
    BOOKING = '/booking/',

    // Tréner
    TODAY = '/today/',

    // Admin
    EVENTS = '/events/',
    NOTICE = '/notice/',
    USERS = '/users/',
    STATISTICS = '/statistics/',
    SETTINGS = '/settings/',
    TEMPLATES = '/templates/',

};

// Zoznam všetkých podstránok
export function GetRoutes(user?: AppContextUser): AppRouteType[] {

    var isLogged = (user?.id ?? 0) > 0;
    var isCoach = user?.coach ?? false;
    var isAdmin = user?.admin ?? false;
    var isUser = isLogged && !isCoach && !isAdmin;

    return [

        // Verejné
        { page: AppRouteUrl.HOME, title: 'Harmonogram cvičení', component: Schedule, exact: true },

        // Všetci prihlásení
        ...(isLogged === true ? [
            { page: AppRouteUrl.CREDIT, title: 'Kredit', component: Credits, exact: true },
            { page: AppRouteUrl.BOOKING, title: 'Rezervácie', component: Bookings, exact: true },
        ] : []),

        // Tréner
        ...(isCoach === true ? [
            { page: AppRouteUrl.TODAY, title: 'Dnešné rezervácie', component: Today, exact: true },
        ] : []),

        // Administrátor
        ...(isAdmin === true ? [
            { page: AppRouteUrl.EVENTS, title: 'Termíny', component: Events, exact: true },
            { page: AppRouteUrl.NOTICE, title: 'Oznam', component: Notice, exact: true },
            { page: AppRouteUrl.USERS, title: 'Užívatelia', component: Users, exact: true },
            { page: AppRouteUrl.STATISTICS, title: 'Štatistiky', component: Statistics, exact: true },
            { page: AppRouteUrl.TEMPLATES, title: 'Šablóny', component: Templates, exact: true },
            { page: AppRouteUrl.SETTINGS, title: 'Nastavenia', component: Settings, exact: true },
        ] : [])
    ];
}

// Predpis pre podstránku 
interface AppRouteType {
    page: AppRouteUrl;
    title: string;
    exact: boolean;
    component: any;
    props?: any;
};