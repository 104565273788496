import React from 'react';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { ChartDataset, Statistic } from '../../models/Models';

// Utility
import { NullMinDate } from '../../utility/Date';
import { endOfMonth, startOfMonth } from 'date-fns';

// Komponenty
import { Alert, Avatar, Backdrop, Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { ContentSimple } from '../../layout/Content';
import { FormatDecimal } from '../../utility/Format';
import StatisticsPanel from './StatisticsPanel';

// Graf
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, Title, } from 'chart.js';
ChartJS.register(ArcElement, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, Title, LineController, BarController);

// Tabuľka - štýl
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        borderBottom: '2px solid #333333'
    },
    [`&.${tableCellClasses.footer}`]: {
        borderTop: '1px solid #333333',
        fontWeight: 600,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    [`&.${tableCellClasses.body}`]: {

    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        borderBottom: 0,
    },
}));

const Statistics = () => {

    // Získanie dátumu od (prvý deň v aktuálnom mesiaci) a dátumu do (posledný deň v aktuálnom mesiaci)
    const defaultDateFrom = startOfMonth(new Date());
    const defaultDateTo = endOfMonth(new Date());

    // Všeobecne
    const [loading, setLoading] = React.useState(false);
    const [dateFrom, setDateFrom] = useState<Date | undefined>(defaultDateFrom);
    const [dateTo, setDateTo] = useState<Date | undefined>(defaultDateTo);
    const [statistics, setStatistics] = useState<Statistic>();

    // Načtať údaje
    const loadData = () => {
        if (NullMinDate(dateFrom) === null) {
            alert('Musíte vyplniť obdobie od!');
            return;
        }
        if (NullMinDate(dateTo) === null) {
            alert('Musíte vyplniť obdobie do!');
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'statistic', {
                params: {
                    from: dateFrom,
                    to: dateTo
                }
            })
            .then(response => {
                if (response.data !== null) {
                    setStatistics(response.data as Statistic);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pekné:   https://demos.creative-tim.com/otis-admin-pro/#/pages/charts
    // Docs:    https://react-chartjs-2.js.org/examples/line-chart

    const chartBarOptions = (title: string) => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' as const, },
            title: {
                display: true,
                text: title
            }
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: { stacked: false, },
            y: { stacked: false, },
        },
        scale: {
            ticks: {
                precision: 0
            }
        }
    });

    // Farby
    const colors = ['#1abc9c', '#3498db', '#e74c3c', '#2ecc71', '#9b59b6', '#34495e'];

    // Datasety
    const GetChartCoachCount = (): any => {
        return ({
            labels: statistics?.coach?.map(item => item.name ?? '') ?? [],
            datasets: [
                { label: 'Jednorázovo', data: statistics?.coach?.map(item => item.count ?? 0) ?? [], backgroundColor: [colors[0]] },
                { label: 'Kredit', data: statistics?.coach?.map(item => item.countCredit ?? 0) ?? [], backgroundColor: [colors[1]] },
                { label: 'Celkom', data: statistics?.coach?.map(item => item.countTotal ?? 0) ?? [], backgroundColor: [colors[2]] },
            ] as ChartDataset[],
        });
    }
    const GetChartCoachPrice = (): any => {
        return ({
            labels: statistics?.coach?.map(item => item.name ?? '') ?? [],
            datasets: [
                { label: 'Tréner', data: statistics?.coach?.map(item => item.price ?? 0) ?? [], backgroundColor: [colors[0]] },
                { label: 'LevFIT', data: statistics?.coach?.map(item => item.priceFee ?? 0) ?? [], backgroundColor: [colors[1]] },
                { label: 'Celkom', data: statistics?.coach?.map(item => item.priceTotal ?? 0) ?? [], backgroundColor: [colors[2]] },
            ] as ChartDataset[],
        });
    }
    const GetChartEventCount = (): any => {
        return ({
            labels: statistics?.event?.map(item => item.name ?? '') ?? [],
            datasets: [
                { label: 'Jednorázovo', data: statistics?.event?.map(item => item.count ?? 0) ?? [], backgroundColor: [colors[0]] },
                { label: 'Kredit', data: statistics?.event?.map(item => item.countCredit ?? 0) ?? [], backgroundColor: [colors[1]] },
                { label: 'Celkom', data: statistics?.event?.map(item => item.countTotal ?? 0) ?? [], backgroundColor: [colors[2]] },
            ] as ChartDataset[],
        });
    }

    return (
        <ContentSimple>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container columnSpacing={4} sx={{ mt: 1 }}>

                <Grid item xs={12} md={5} my={3}>
                    <Typography variant='h4'>Štatistiky</Typography>
                    <Typography variant='body2'>Zo štatistík sú vylúčení užívatelia s emailom ...@levfit.sk</Typography>
                </Grid>

                <Grid item xs={12} md={7} mb={3}>
                    <Paper sx={{ p: 2 }}>
                        <StatisticsPanel dateFrom={dateFrom} dateTo={dateTo} onChangeDateFrom={(d) => setDateFrom(d)} onChangeDateTo={(d) => setDateTo(d)} onShow={loadData} />
                    </Paper>
                </Grid>

                {/* Počet vstupov podľa trénera */}

                <Grid item xs={12} md={6} mb={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Počet vstupov</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Jednorázovo</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Kredit</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Celkom</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statistics?.coach?.map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        <StyledTableCell>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.count ?? 0}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.countCredit ?? 0}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.countTotal ?? 0}
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.coach?.reduce((sum, row) => sum + (row.count ?? 0), 0)}</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.coach?.reduce((sum, row) => sum + (row.countCredit ?? 0), 0)}</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.coach?.reduce((sum, row) => sum + (row.countTotal ?? 0), 0)}</StyledTableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={6} mb={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                        <Box sx={{ height: '100%', minHeight: '300px', maxHeight: '400px' }}>
                            <Bar data={GetChartCoachCount()} options={chartBarOptions('Počet vstupov podľa trénera')} />
                        </Box>
                    </Box>
                </Grid>

                {/* Obrat podľa trénera */}

                <Grid item xs={12} md={6} mb={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Obrat v EUR</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Tréner</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>LevFIT</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Celkom</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statistics?.coach?.map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        <StyledTableCell>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {FormatDecimal(row.price ?? 0, 2)} EUR
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {FormatDecimal(row.priceFee ?? 0, 2)} EUR
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {FormatDecimal(row.priceTotal ?? 0, 2)} EUR
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{FormatDecimal(statistics?.coach?.reduce((sum, row) => sum + (row.price ?? 0), 0) ?? 0, 2)} EUR</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{FormatDecimal(statistics?.coach?.reduce((sum, row) => sum + (row.priceFee ?? 0), 0) ?? 0, 2)} EUR</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{FormatDecimal(statistics?.coach?.reduce((sum, row) => sum + (row.priceTotal ?? 0), 0) ?? 0, 2)} EUR</StyledTableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={6} mb={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                        <Box sx={{ height: '100%', minHeight: '300px', maxHeight: '400px' }}>
                            <Bar data={GetChartCoachPrice()} options={chartBarOptions('Obrat podľa trénera')} />
                        </Box>
                    </Box>
                </Grid>

                {/* Počet vstupov podľa skupinoviek */}

                <Grid item xs={12} md={6} mb={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Počet vstupov</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Jednorázovo</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Kredit</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>Celkom</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statistics?.event?.map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        <StyledTableCell>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.count ?? 0}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.countCredit ?? 0}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            {row.countTotal ?? 0}
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.event?.reduce((sum, row) => sum + (row.count ?? 0), 0)}</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.event?.reduce((sum, row) => sum + (row.countCredit ?? 0), 0)}</StyledTableCell>
                                    <StyledTableCell sx={{ width: 110, textAlign: 'center' }}>{statistics?.event?.reduce((sum, row) => sum + (row.countTotal ?? 0), 0)}</StyledTableCell>
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={6} mb={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                        <Box sx={{ height: '100%', minHeight: '300px', maxHeight: '400px' }}>
                            <Bar data={GetChartEventCount()} options={chartBarOptions('Počet vstupov podľa skupinovky')} />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </ContentSimple>
    )
}

export default Statistics;
