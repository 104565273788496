import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppContext } from '../../AppContext';

// Utility
import { ConvertToInt } from '../../utility/Convert';

// Modely
import { User } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField, Box, Checkbox, FormControlLabel, Typography, Link, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    severity: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    severity: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): User => ({
    id: 0,
    email: '',
    name: '',
    phone: '',
    password: '',
    note: '',
    fileName: 'avatar3.jpg'
});

// Vstupné parametre
export interface AccountProps {
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const Account = (props: AccountProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<User>(EmptySource());
    const [sourcePasswordVerify, setSourcePasswordVerify] = useState<string | undefined>(undefined);
    const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const context = useContext(AppContext);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        setSourcePasswordVerify('');
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user/account/')
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as User);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.email === '') {
            setWarning({ ...EmptyWarning, name: 'email', text: 'Musíte vyplniť: email!' });
            return;
        }
        if (source.name === '') {
            setWarning({ ...EmptyWarning, name: 'name', text: 'Musíte vyplniť: celé meno!' });
            return;
        }
        if ((source.password?.length ?? 0) > 0) {
            const password = source.password ?? '';
            const passwordVerify = sourcePasswordVerify ?? '';
            if (password !== passwordVerify) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslá sa nezhodujú!' });
                return;
            }
        }
        if ((source.id ?? 0) === 0 && acceptTerms === false) {
            setWarning({ ...EmptyWarning, name: 'terms', text: 'Pokračovať môžete len ak potvrdíte, že ste oboznámený s obchodnými podmienkami!' });
            return;
        }
        if ((source.id ?? 0) === 0 && acceptPrivacy === false) {
            setWarning({ ...EmptyWarning, name: 'privacy', text: 'Pokračovať môžete len ak potvrdíte súhlas so spracovaním osobných údajov!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user/account', source)
            .then(response => {
                if (response.data) {

                    // Aktualizovaný profil
                    if (response.data.success === true && response.data.data === 'updated') {
                        context.setContext?.(prev => ({ ...prev, user: { ...prev.user, name: source.name ?? '' } }));
                        props.onSave?.();
                        setWarning({ ...EmptyWarning, severity: 'success', name: 'email', text: 'Uložené!' });
                    }

                    // Nová registrácia - email už existuje
                    if (response.data.success === false && response.data.data === 'emailexist') {
                        setWarning({ ...EmptyWarning, text: 'Zadaná emailová adresa už v systéme existuje!' });
                        return;
                    }

                    // Nová registrácia - overovací email sa nepodarilo odoslať
                    if (response.data.success === false && response.data.data === 'notsent') {
                        setWarning({ ...EmptyWarning, text: 'Overovací email sa nepodarilo odoslať, skúste to prosím neskôr znovu alebo nás kontaktujte!' });
                        return;
                    }

                    // Nová registrácia - hotovo, bolo odoslaný overovací email
                    if (response.data.success === true && response.data.data === 'created') {
                        alert('Registrácia prebehla úspešne! Na Vašu emailovú adresu bol odoslaný overovací email s odkazom pre dokončenie registrácie (registráciu potvrďte kliknutím na zaslaný odkaz).');
                        props.onSave?.();
                        props.onClose();
                        return;
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť!' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ user: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((source?.id ?? 0) === 0 ? 'Vytvoriť nový účet' : 'Môj účet')}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={props.onClose} sx={{ position: 'absolute', right: 8, top: 8, opacity: .8, background: 'whitesmoke' }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} my={1}>
                                <Typography variant='h6'>Prihlasovacie údaje</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required={(source?.id ?? 0) === 0} disabled={(source?.id ?? 0) > 0} margin="dense" name="email" type="email" label='Email' fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.id ?? 0) === 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <TextField required margin="dense" error={warning.name === 'password'} name="password" type="password" label='Heslo' fullWidth variant="outlined" autoComplete="new-password" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 70 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField required margin="dense" error={warning.name === 'password'} name="passwordverify" type="password" label='Heslo znovu' fullWidth variant="outlined" autoComplete="new-password" value={sourcePasswordVerify ?? ''} onChange={(e => { setSourcePasswordVerify(e.target.value); })}
                                            inputProps={{ maxLength: 70 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} my={1}>
                                <Typography variant='h6'>Osobné údaje</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label='Celé meno' fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField margin="dense" name="phone" type="phone" label='Telefón' fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField fullWidth margin="dense" name="birthYear" type="number" label="Rok narodenia" variant="outlined" value={(source.birthYear ?? 0) > 0 ? source.birthYear : ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputProps={{ min: 1900, max: 2200 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EventIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} my={1}>
                                <Typography variant='h6'>Adresa <span style={{ color: '#555', fontWeight: 300, fontSize: '14px' }}> - pre účely vystavenia dokladu</span></Typography>

                            </Grid>


                            <Grid item xs={12}>
                                <TextField required margin="dense" name="street" type="street" label='Ulica a číslo domu' fullWidth variant="outlined" autoComplete="off" value={source.street ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PlaceIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={8}>
                                <TextField required margin="dense" name="city" type="city" label='Mesto' fullWidth variant="outlined" autoComplete="off" value={source.city ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PlaceIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField required margin="dense" name="postcode" type="postcode" label='PSČ' fullWidth variant="outlined" autoComplete="off" value={source.postcode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PlaceIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.id ?? 0) === 0 && (
                                <>
                                    <Grid item xs={12} mt={2}>
                                        <FormControlLabel sx={{ mr: 0.5 }} control={<Checkbox size="small" value={acceptTerms} onChange={(e) => { setAcceptTerms(e.target.checked); }} name="acceptTerms" color="primary" />} checked={acceptTerms} label='Potvrdzujem, že som sa oboznámil s ' />
                                        <Link sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }} href={AppConfig.UrlTerms} target="_blank">obchodnými podmienkami</Link>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel sx={{ mr: 0.5 }} control={<Checkbox size="small" value={acceptPrivacy} onChange={(e) => { setAcceptPrivacy(e.target.checked); }} name="acceptPrivacy" color="primary" />} checked={acceptPrivacy} label='Súhlasím so spracovaním ' />
                                        <Link sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }} href={AppConfig.UrlPrivacy} target="_blank">osobných údajov</Link>
                                    </Grid>
                                </>
                            )}

                            {(warning.text.length > 0 && <Grid item xs={12} mt={2}><Alert sx={{ mt: 1 }} severity={warning.severity as AlertColor}>{warning.text}</Alert></Grid>)}

                            <Grid item xs={12} mt={2}>
                                <Button type="submit" disabled={loading} sx={{ mt: 1, mb: 1 }} fullWidth size="large" variant="contained">{((source?.id ?? 0) === 0 ? 'Zaregistrovať' : 'Uložiť')}</Button>
                                <Button type="button" disabled={loading} sx={{ mb: 1 }} fullWidth size="large" variant="outlined" onClick={props.onClose}>Zrušiť</Button>
                            </Grid>

                            {(source.id ?? 0) === 0 && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity='info'>
                                        Na Vašu emailovú adresu bude odoslaný overovací email s odkazom pre dokončenie registrácie (registráciu potvrďte kliknutím na zaslaný odkaz).
                                    </Alert>
                                </Grid>
                            )}

                        </Grid>

                    </DialogContent>
                </Box>
            </Dialog >
        </>
    )
}

export default Account;