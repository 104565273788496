// Komponenty
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
    children: any;
    invert?: boolean;
}

//------------------------------------------------
// Nadpis pre obsah
//------------------------------------------------

interface ContentTitleProps {
    title?: string;
    subtitle?: string;
}

export const ContentTitle = (props: ContentTitleProps) => {

    const theme = useTheme();
    const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box sx={{ textTransform: 'uppercase', mt: 2, mb: 3 }}>
            <Typography noWrap variant={breakpointMd ? 'h2' : 'h3'} fontWeight='bold'>{props.title}</Typography>
            <Typography noWrap variant={breakpointMd ? 'h4' : 'h5'} fontWeight={300}>{props.subtitle}</Typography>
        </Box>
    )
}

//------------------------------------------------
// Obsah bez panelu
//------------------------------------------------

export const ContentSimple = (props: Props) => {
    return (
        <Box sx={{ paddingBottom: '150px' }}>
            {props.children}
        </Box>
    )
}

//------------------------------------------------
// Obsah s panelom - podľa invert je panel hore alebo dole
//------------------------------------------------

export const Content = (props: Props) => {
    return (props.invert ?? false) === false ?
        (
            <Box sx={{
                display: 'grid', width: '100%', height: '100%', rowGap: '10px', paddingBottom: '15px',
                gridTemplateAreas: ' "top" "bottom" ',
                gridTemplateRows: {
                    xs: '100px auto',
                    md: '50px auto'
                }
            }}>
                {props.children}
            </Box>
        )
        :
        (
            <Box sx={{
                display: 'grid', width: '100%', height: '100%', rowGap: '10px', paddingBottom: '15px',
                gridTemplateAreas: ' "bottom" "top" ',
                gridTemplateRows: 'auto 50px'
            }}>
                {props.children}
            </Box>
        );
}
// Panel
export const ContentPanel = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'top' }}>
            {props.children}
        </Box>
    )
}
// Obsah
export const ContentMain = (props: Props) => {
    return (
        <Box sx={{ gridArea: 'bottom' }}>
            {props.children}
        </Box>
    )
}
