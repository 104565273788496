import React, { useState } from 'react';

// Utility
import { format as dateFormat } from 'date-fns';
import { NullMinDate } from '../../utility/Date';
import { FormatDecimal } from '../../utility/Format';

// Modely
import { EventBooking } from '../../models/Models';

// Komponenty
import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

interface EventDetailBookingProps {
    bookings?: EventBooking[];
}

const EventDetailBooking = (props: EventDetailBookingProps) => {

    // Všeobecne
    const theme = useTheme();
    const breakpointSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [expanded, setExpanded] = useState<number[]>([]);

    // Formátovanie dátumu
    const formatDate = (date: any): string => {
        if (NullMinDate(date) === null) {
            return '-';
        }
        return dateFormat(new Date(date), 'dd.MM.yyyy HH:mm');
    }

    // Prepnúť rozbaľovanie
    const handleToggleExpanded = (index: number) => setExpanded(prev => prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]);

    return (
        <Paper variant="outlined">
            <List dense sx={{ padding: 0 }}>
                {props.bookings?.map((booking, bookingIndex) =>
                    <React.Fragment key={bookingIndex}>
                        <ListItem alignItems="flex-start">
                            <Button onClick={() => handleToggleExpanded(bookingIndex)} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }}></Button>
                            {breakpointSm && <ExpandCircleDownIcon sx={{ position: 'absolute', top: '18px', right: '20px', opacity: .2, ...(expanded.includes(bookingIndex) ? { transform: 'rotate(180deg)' } : {}) }} fontSize="medium" />}
                            {breakpointSm &&
                                <ListItemAvatar>
                                    {booking?.fileIsImage ?
                                        <Avatar sx={{ width: 40, height: 40, mt: -.2 }} alt={booking?.name} src={booking?.fileSrcSmall} /> :
                                        <Avatar sx={{ width: 40, height: 40 }}><PersonIcon /></Avatar>
                                    }
                                </ListItemAvatar>
                            }
                            <ListItemText
                                primary={<>
                                    <strong>{booking.name}</strong>
                                </>}
                                secondary={<>
                                    Email: <u>{booking.email}</u><br />
                                    {(expanded.includes(bookingIndex)) && <>
                                        Telefón: <u>{(booking.phone ?? '').length > 0 ? booking.phone : '-'}</u><br />
                                        Rezervácia číslo: <u>#{booking.id}</u><br />
                                        Vytvorenie: <u>{formatDate(booking?.createdDate)}</u><br />
                                        {NullMinDate(booking.cancelledDate) !== null && <>Zrušenie: <u>{formatDate(booking?.cancelledDate)}</u><br /></>}
                                        Platba: <u>{FormatDecimal(booking.price ?? 0, 2)} EUR</u>
                                    </>}
                                </>}
                            />
                        </ListItem>
                        {(bookingIndex + 1) < (props.bookings?.length ?? 0) && <Divider component="li" />}
                    </React.Fragment>
                )}
            </List>
        </Paper>
    )
}

export default EventDetailBooking