import React, { useCallback } from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { format as dateFormat } from 'date-fns';

// Modely
import { Booking, BookingGroup, Event } from '../../models/Models';
import { AppContext } from '../../AppContext';

// Komponenty
import { Alert, AlertTitle, Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { ContentSimple, ContentTitle } from '../../layout/Content';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import BookingsList from './BookingsList';

const Bookings = () => {

    // Všeobecne
    const context = useContext(AppContext);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [loading, setLoading] = React.useState(true);

    // Upozornenie na nedokončené rezervácie
    const [failed, setFailed] = useState<Event[]>([]);

    // Tabuľka
    const [rows, setRows] = useState<BookingGroup[] | undefined>(undefined); // 'undefined', aby sa pri vstupe nezobrazilo (žiadne záznamy)
    const rowsPageSize: number = 2;
    const [rowsPageIndex, setRowsPageIndex] = useState<number>(rowsPageSize);

    // Načtať kompletné dáta
    const loadData = useCallback(() => {
        axios
            .get(AppConfig.ApiUri + 'booking/group/')
            .then(response => {
                if (response.data !== null) {
                    setRows(response.data as BookingGroup[]);
                }
                else {
                    setRows([]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        axios
            .get(AppConfig.ApiUri + 'booking/failed/')
            .then(response => {
                if (response.data !== null) {
                    setFailed(response.data as Event[]);
                }
                else {
                    setFailed([]);
                }
            });
    }, []);

    // Vykonám kontrolu ihneď po zobrazení kontrolu
    useEffect(() => {
        setLoading(true);
        loadData();
        // Vykonám kontrolu každých N sekúnd (už nezobrazujem preloader, ani nepreskočím na prvú stranu)
        const interval = setInterval(() => loadData(), (10 * 1000));
        return () => clearInterval(interval);
    }, []);

    // Zrušenie rezervácie
    const handleCancel = (booking: Booking) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Zrušenie rezervácie',
            children: <>
                <u>{booking.event?.name} - {dateFormat(new Date(booking.event?.date ?? new Date()), 'HH:mm') + ' (' + dateFormat(new Date(booking.event?.date ?? new Date()), 'dd.MM.yyyy') + ')'}</u><br />
                Skutočne chcete zrušiť Vašu rezerváciu?
            </>,
            onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'booking/cancel/' + booking.id)
                    .then(response => {
                        if (response.data) {

                            // Rezervácia zrušená (obnovím zoznam a context kvôli prepočtu kreditu)
                            if (response.data.success === true) {
                                loadData();
                                context.userInfoLoad?.();
                                return;
                            }

                            // Rezerváciu už nebolo možné zrušiť
                            if (response.data.success === false && response.data.data === 'closed') {
                                loadData();
                                alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nPrihlasovanie je už uzavreté!');
                                return;
                            }

                            // Neznáma chyba
                            alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nNastala neznáma chyba!');
                        }
                    })
                    .catch(() => alert('Ľutujeme, ale rezerváciu už nebolo možné zrušiť.\nNastala neznáma chyba!'))
                    .finally(() => setLoading(false));
            }
        }));
    };

    return (
        <ContentSimple>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Nadpis */}
            <ContentTitle title="Moje" subtitle="Rezervácie" />

            {/* Upozornenie na jednorázovú platbu */}
            {(context.userInfo?.credit ?? 0) === 0 && <Typography sx={{ mb: 3 }} variant='body2'>
                Pri jednorázovej platbe môže trvať určitý čas (niekoľko sekúnd), kým sa Vaša platba spracuje a rezervácia sa zobrazí v zozname rezervácií. 
            </Typography>}

            {/* Upozornenie - nedokončená rezervácia */}
            {failed.length > 0 && (
                <Box sx={{ mb: 3 }}>
                    <Alert severity="error">
                        <AlertTitle>Upozornenie</AlertTitle>
                        <strong>Niektorá z Vašich žiadostí o rezerváciu termínu nemohla byť dokončená kvôli uzavretiu prihlasovania.</strong><br />
                        Vaša platba Vám bola automaticky vrátená späť na účet.
                        Termíny majú obmedzenú kapacitu a preto je možné, že sa počas Vašej platby vyčerpali voľné miesta.
                        Pri jednorázovej platbe je rezervácia potvrdená až po dokončení celého procesu platby.
                        Aby Vás pri rezervácii nikto nepredbehol, odporúčame Vám využiť kreditný systém, ktorý vykoná rezerváciu takmer okamžite.
                        <br /><br />
                        {failed.map((item) => <React.Fragment key={item.id}>Termín: <strong>{item.name}</strong> ({dateFormat(new Date(item.date ?? new Date()), 'dd.MM.yyyy HH:mm')})<br /></React.Fragment>)}
                    </Alert>
                </Box>
            )}

            {/* Rezervácie - zoskupenie po mesiacoch */}
            <BookingsList rows={rows} onCancel={handleCancel} />

        </ContentSimple>
    )
}

export default Bookings;
