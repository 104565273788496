import { GridColDef, GridColumnVisibilityChangeParams, GridLocaleText } from "@mui/x-data-grid";
import { skSK } from "@mui/x-data-grid";

// Utility
import { ExportCSV } from './ExportCSV';
import { ExportXML } from './ExportXML';

//-----------------------------------------------------------------------------
// LOCALIZATION (lokalizácia podľa nastavení)
//-----------------------------------------------------------------------------

export function LocalizeGrid(): Partial<GridLocaleText> {
    return skSK.components.MuiDataGrid.defaultProps.localeText;
};

//-----------------------------------------------------------------------------
// DATAGRID-SETTINGS (automatické ukladanie nastavení gridu)
//-----------------------------------------------------------------------------

/** Prázdne nastavenia */
const EmptySettingsStorage = (): SettingsStorage => ({
    pageSize: 0,
    columns: []
});

/** Predpis pre lokálne úložisko nastavení a prázdne nastavenia */
interface SettingsStorage {
    /** Veľkosť strany */
    pageSize: number;
    /** Stav stĺpcov */
    columns: SettingsStorageColumn[]
}
interface SettingsStorageColumn {
    /** Názov stĺpca */
    field: string;
    /** Skrytý */
    hide: boolean;
}

/** Vstupné parametre pre triedu obsluhujúcu stav stĺpcov */
interface SettingsProps {
    /** Unikátny identifikátor pre datagrid, ukladajú sa podľa neho nastavenia do localStorage */
    uid: string;
}

/** Trieda obsluhujúca automatické ukladanie nastavení stĺpcov */
export class Settings {
    props: SettingsProps | undefined;
    storageName: string = "dgv-settings";
    
    constructor(props: SettingsProps) {
        this.props = props;
    }

    /** Načíta uložené nastavenia */
    load = (): SettingsStorage => {
        try {
            const settings = localStorage.getItem(this.storageName + '-' + this.props?.uid);
            if (settings !== null) {
                return JSON.parse(settings)
            }
        }
        catch (error) { }
        return EmptySettingsStorage();
    };

    /** Uloží nastavenia */
    save = (settings: SettingsStorage) => {
        try {
            localStorage.setItem(this.storageName + '-' + this.props?.uid, JSON.stringify(settings));
        }
        catch (error) { }
    };

    /** Aplikuje uloženú veľkosť strany  */
    pageSizeApply = (pageSize: number): number => {
        const settings = this.load();
        if (settings !== null && settings.pageSize > 0) {
            return settings.pageSize;
        }
        return pageSize;
    };

    /** Zachytí a uloží zmenu veľkosti strany */
    pageSizeChanged = (pageSize: number) => {
        let settings = this.load();
            this.save({ ...settings, pageSize: pageSize });
    };
  
    /** Aplikuje uložené nastavenia stĺpcov */
    columnApply = (columns: GridColDef[]): GridColDef[] => {
        const settings = this.load();
        if (settings !== null && settings.columns.length > 0) {
            const columnsApplied = [...columns];
            settings.columns.forEach(c => {
                for (var i = 0; i < columnsApplied.length; i++) {
                    if (columnsApplied[i].field === c.field) {
                        columnsApplied[i].hide = c.hide;
                    }
                }
            });
            return columnsApplied;
        }
        return columns;
    };

    /** Zachytí a uloží zmeny v nastaveniach stĺpcov */
    columnVisibilityChanged = (e: GridColumnVisibilityChangeParams, columns: GridColDef[]) => {
        if(e.field === 'options') {
            return; // nedovolím uložiť zmenu zobrazenia pre stĺpec "options" (mohlo by sa stať, že skryjem všetky stĺpce a nebudem mať možnosť ich znovu obnoviť)
        }
        if(e.field === 'optionsQuantity') {
            return; // nedovolím uložiť zmenu zobrazenia pre stĺpec "optionsQuantity" (ide o stĺpec, ktorý slúži na zadávanie množstva pri výbere produktov)
        }
        let settings = this.load();
        let hidden = !e.isVisible;
        let hiddenDefault = columns.find(c => c.field === e.field)?.hide;
        if (hiddenDefault === hidden) {
            this.save({ ...settings, columns: [...settings.columns.filter(c => c.field !== e.field)] }); // ak je nastavenie rovnaké ako defaultné, tak ho nemusím ukladať
        }
        else {
            this.save({ ...settings, columns: [...settings.columns.filter(c => c.field !== e.field), { field: e.field, hide: hidden }] }); // ak je nastavenie iné ako defaultné, tak ho uložím
        }
    };
};

//-----------------------------------------------------------------------------
// Export (automatický export podľa stĺpcov v gride)
//-----------------------------------------------------------------------------

/** Typ exportu */
type ExportPropsType = 'xml' | 'csv';

/** Vstupné parametre pre export */
interface ExportProps {
    /** Typ exportu */
    type: ExportPropsType;
    /** Stĺpce tabuĺky */
    columns: GridColDef[];
    /** Stĺpce, ktoré sa majú vynechať */
    columnsSkip: string[];
    /** Záznamy pre export */
    rows: any[];
    /** ID záznamov pre export (ak je prázdne exportuje sa všetko) */
    ids?: number[];
    /** Výnimky pre jednotlivé stĺpce */
    specific?: ExportPropsSpecific[];
}

/** Výnimky pre jednotlivé stĺpce, napr. ak je hodnota vnorená, alebo sa má preformátovať a podobne */
interface ExportPropsSpecific {
    /** Názov stĺpca */
    field: string;
    /** Výstupný názov stĺpca (napr. pre XML) */
    fieldName?: string;
    /** Funkcia, ktorá vráti požadovanú hodnotu */
    getValue: (row: any) => string;
}

/** Export dát z datagridu podľa zobrazených stĺpcov */
export const Export = (props: ExportProps) => {

    // Vygeneruje dáta pre CSV
    const GenerateCSVData = (): any[] => {
        const rows = (props.ids === undefined ? props.rows : props.rows.filter(r => props.ids?.includes(r?.id ?? 0)));
        const columns = props.columns.filter(column => !column.hide && !props.columnsSkip.includes(column.field)).map(column => column.headerName);
        const data: any[] = [
            columns,
            // Prejdem riadky
            ...rows.map(row =>
                // Namapujem stĺpce
                props.columns.map(column => {
                    if (column.hide || props.columnsSkip.includes(column.field)) {
                        return null;
                    }
                    // Výnimky
                    if (props.specific !== null && props.specific?.find(item => item.field === column.field)) {
                        return props.specific?.find(item => item.field === column.field)?.getValue(row) ?? '';
                    }
                    if (column.type === 'date') {
                        if ((row as any)[column.field] === null) {
                            return '';
                        }
                        var d: Date = new Date((row as any)[column.field]);
                        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
                    }
                    return (row as any)[column.field] ?? '';
                }).filter(value => value !== null)
            )
        ];
        return data;
    };

    // Vygeneruje dáta pre XML
    const GenerateXMLData = (): any[] => {
        const rows = (props.ids === undefined ? props.rows : props.rows.filter(r => props.ids?.includes(r?.id ?? 0)));
        const data: any[] = [];
        rows.forEach(row => {
            var newItem = {};
            props.columns.forEach(column => {
                if (!column.hide && !props.columnsSkip.includes(column.field)) {
                    var field = column.field;
                    var value = null;
                    // Výnimky
                    if (props.specific !== null && props.specific?.find(row => row.field === column.field)) {
                        field = props.specific?.find(row => row.field === column.field)?.fieldName ?? column.field;
                        value = props.specific?.find(row => row.field === column.field)?.getValue(row) ?? '';
                    }
                    if (value === null) {
                        value = (row as any)[field] ?? '';
                    }
                    (newItem as any)[field] = value;
                }
            });
            data.push(newItem);
        });
        return data;
    };

    // Vyexportujem dáta
    switch (props.type) {
        case 'csv': {
            ExportCSV(GenerateCSVData());
            break;
        }
        case 'xml': {
            ExportXML({ data: { item: GenerateXMLData() } });
            break;
        }
    }
};

